import { Button, Form, FormLabel } from "react-bootstrap-v5";
import { HtmlContentTitle, HtmlTooltipStyled } from "../../../../../../../_start/helpers/components/HtlmTooltip";
import { useDataConfiguracion } from "../../../core/ConfiguracionCore";
import { CargaListadoClientes } from "./ComponenteCliente";
import { getClientes, setDataParamSistema, Tooltips } from "../../../data/dataConfiguracion";
import { useEffect, useState } from "react";
import { getVehiculosClienteId } from "../../../../../../../_start/helpers/Axios/DWHService";
import { AxiosError, AxiosResponse } from "axios";
import { useToaster } from "rsuite";
import { MensajeError, MensajeSuccess } from "../../../../../../../_start/helpers/components/Toaster";
import { ConfigAssets } from "../../../data/dataConfigAssets";
import { GetSites } from "../../../../../Seguridad/Administracion/data/Clientes";
import { Checkbox } from "@mui/material";
import confirmarDialog from "../../../../../../../_start/helpers/components/ConfirmDialog";
import BlockUi from "@availity/block-ui";


/**Permite configurar los vehiculos que se utilizaran para la aplicación, la extracción puede ser de sitio o vehículos específico 
 * de cada sitio. 
 * En el servicio que extrae la información se filtrará esta información por los vehículos seleccionados, si no hay ninguno
 * se asumirá que serán todos los que cuenta el cliente.
 */
export const PREOP_ConfigAssets: React.FC = () => {
    const { datosPreoperacional, setclienteSeleccionado, clienteSeleccionado, showObject, setshowObject
        , setClientes, Clientes } = useDataConfiguracion();
    const toaster = useToaster();
    const [lstVehiculosCliente, setLstVehiculoCliente] = useState<any[]>([]);
    const [lstSites, setLstSites] = useState<any[]>([]);
    const [lstVehiculosOriginal, setlstVehiculosOriginal] = useState<any[]>([]);
    const [lstSeleccionados, setLstSeleccionados] = useState<any[]>([]);
    const [nodosExpandidos, setNodosExpandidos] = useState<any[]>([]);
    const [filtro, setFiltro] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // iniciamos obteniendo los sites que la fuente princupal del contro
        if (clienteSeleccionado !== undefined && clienteSeleccionado.ClienteId !== '0') {
            consultarGetSites(); // obtiene la información de los sites
        }

        return () => {
            setLstVehiculoCliente([])
        }

    }, [clienteSeleccionado]);

    // trae la información de los assets que se han guardado en los clientes
    useEffect(() => {
        // traemos los datos guardados en el control
        if (Clientes.length > 0) {
            const preop_config = JSON.parse(Clientes[0].ParamsSistema)?.preop_config ?? { assets: [] }; // validamos que exista el parametro
            // y se lo creamos 
            setLstSeleccionados(preop_config.assets ?? []);
        }

        return () => {
            setLstSeleccionados([])
        }

    }, [Clientes]);
    useEffect(() => {
        // cada vez que seleccionamos o quitamos actualizamos el array para que se actualize la visualización
        const lstVehiculosFiltrados = lstVehiculosOriginal.filter(f =>
        (filtro === '' ||
            (f.registrationNumber.toUpperCase().includes(filtro.toUpperCase()) || f.description.toUpperCase().includes(filtro.toUpperCase()))))
        let lstDatosanidados = ConfigAssets.getSitesAnidadosTree(lstSites, null, lstVehiculosFiltrados, lstSeleccionados);
        setLstVehiculoCliente(lstDatosanidados);
    }, [lstSeleccionados, filtro]);

    const consultarVehiculos = (lstSitesAnidados: any[]) => {
        setLoading(true)
        getVehiculosClienteId(clienteSeleccionado.ClienteId, 'Available').then((response: AxiosResponse) => {
            if (response.status === 200) {
                // guardamos el array original para actualizar los selecciondfos
                setlstVehiculosOriginal(response.data);
                // transformamos los datos en un array anidado para pderse mostrar en el treeview
                let lstDatosanidados = ConfigAssets.getSitesAnidadosTree(lstSitesAnidados, null, response.data, lstSeleccionados);
                setLstVehiculoCliente(lstDatosanidados);
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error al cargar vehículos', error.message), { placement: 'topCenter' })
        }).finally(() => { setLoading(false) });
    }
    const consultarGetSites = () => {
        setLoading(true)
        GetSites(clienteSeleccionado.ClienteId).then((response: AxiosResponse) => {
            if (response.status === 200) {
                setLstSites(response.data);
                // consultamos los vehículos apenas la información de los sites esté disponible
                consultarVehiculos(response.data)
            }
        }).catch((error: AxiosError) => {
            toaster.push(MensajeError('Error al cargar Sites', error.message), { placement: 'topCenter' })
        }).finally(() => { setLoading(false) });
    }


    const ConsultarClientesDatos = () => {
        getClientes(String(clienteSeleccionado.ClienteIds)).then((response: AxiosResponse<any>) => {
            if (response.status === 200)
                setClientes(response.data);
        }).catch((error: AxiosError<any>) => {
            toaster.push(MensajeError('Error al cargar Datos del Cliente', error.message), { placement: 'topCenter' })
        });
    }
    // guarda la información de los vehiculos en params sistema preop_config con la propiedad assets en donde se guardarán 
    // los assets permitidos para traer información de eventos del preoperacional y poderlos filtrar en las consultas 
    // que se mostrarán en el listado general
    const GuardarlistadoVehiculos = () => {

        confirmarDialog(() => {
            setLoading(true);
            const preop_config = JSON.parse(Clientes[0].ParamsSistema)?.preop_config ?? {};
            preop_config.assets = lstSeleccionados;
            setDataParamSistema(clienteSeleccionado.ClienteIds, "$.preop_config", JSON.stringify(preop_config)).
                then((response: AxiosResponse<any>) => {
                    if (response.status === 200) {
                        toaster.push(MensajeSuccess('Guardar Vehiculos', 'Guardado exitosamente'), { placement: 'topCenter' });
                        ConsultarClientesDatos();
                    }

                }).catch((error: AxiosError<any>) => {
                    toaster.push(MensajeError('Guardar Vehiculos', error.message), { placement: 'topCenter' })
                }).finally(() => { setLoading(false); })
        }, `¿Esta seguro que desea guardar los cambios?`, 'Guardar');
    }

     
 
    const TreeNode: React.FC<{ node: any }> = ({ node }) => {
        // toggle que indica que va a esar el nodo expandido o no
        // verificamos del listado si existe el nodo extendido para setearlo 
        const isExpanded = nodosExpandidos.find(f => f === node.SiteId)
        const [expanded, setExpanded] = useState(node.Node === 1 ? isExpanded != null : false);
        const id = node.Node === 1 ? node.SiteId : node.AssetId; // toca determinar si es nodo o un hijo con ello viene el comportamiento del control
        const handleToggle = () => {
            const nuevoEstado = !expanded;
            setExpanded(nuevoEstado);
            let lstNodosExpandidos = [...nodosExpandidos];

            if (node.Node === 1)
                if (nuevoEstado) {
                    if (!lstNodosExpandidos.includes(id)) lstNodosExpandidos.push(id)
                }
                else
                    lstNodosExpandidos = lstNodosExpandidos.filter(f => f !== id);
            setNodosExpandidos(lstNodosExpandidos);
        };

        // verifica que exista la propiedad y sea de tipo array de lo contrario lo plasma en o
        const hijos: any[] = Array.isArray(node.subRows) ? node.subRows : [];
        const isRoot = hijos.length > 0;
        const classnameNode: string = isRoot ? 'fs-4 text-green fw-bold' : 'fs-3 text-syscaf-primary'

        // verificamos si desde el nodo existe en varios niveles vehiculos seleccionados

        let allAssets = ConfigAssets.getAssetsAnidados(hijos); // listado de assets en todos los niveles inferiores
        let selectForNode = allAssets.filter(f => lstSeleccionados.includes(f));  // seleccionados para el nodo en específico 
        const totalAssetsNodo = allAssets.length;
        const toalAsstesNodoSel = selectForNode.length;
        return (<>
            <li>
                {isRoot && (
                    <span className='text-syscaf-verde' onClick={handleToggle} style={{ cursor: 'pointer', marginRight: 5 }}>
                        {expanded ? '▼' : '▶'}
                    </span>
                )}
                <Checkbox
                    color={(totalAssetsNodo !== toalAsstesNodoSel) ? "warning" : "primary"}
                    checked={node.Node === 1 ? toalAsstesNodoSel > 0 : node.seleccionado}
                    onChange={(ev: any, checked: boolean) => {

                        //marcamos los nodos expandidos o no para que a pesar de los cambios los demás elementos nose colapsen
                        let lstNodosExpandidos = [...nodosExpandidos];

                        if (node.Node === 1)
                            if (checked) {
                                if (!lstNodosExpandidos.includes(id)) lstNodosExpandidos.push(id)
                            }
                        setNodosExpandidos(lstNodosExpandidos);


                        // esto es importante pues detemrina a quienes se adiciona no no.
                        let seleccionados = [...lstSeleccionados]; // hacemos una copia de los seleccioandos
                        // verificamos que no existan en el array y si se quita se deschulea e la accion
                        if (node.Node !== 1) // si no es nodo y es hijo, se verifica y se agrega/ elimina al array                         
                            if (checked) {
                                if (!seleccionados.includes(id)) seleccionados.push(id)
                            }
                            else
                                seleccionados = seleccionados.filter(f => f !== id)

                        if (node.Node === 1)    // si es nodo debemos buscar todos los hijos y adiconarlos al array.
                        { // traemos todos los hijos que tiene y verificamos que no existan

                            allAssets.forEach(
                                f => {
                                    if (checked) {
                                        if (!seleccionados.includes(f)) seleccionados.push(f);
                                    }
                                    else
                                        seleccionados = seleccionados.filter(fff => fff != f)
                                }
                            );
                        }
                        setLstSeleccionados(seleccionados); // seteamos la información
                    }} />
                <span className={classnameNode}> {node.Node == 1 ?
                    `${node.Sitio} ( ${toalAsstesNodoSel} de ${totalAssetsNodo} seleccionados)`
                    : `${node.description} (${node.registrationNumber})`}</span>
                {isRoot && expanded && (
                    <ul className='mx-2' style={{ listStyleType: 'none', paddingLeft: 20 }}>
                        {hijos?.map((childNode: any) => {
                            return <TreeNode key={childNode.subRows?.length > 0 ? childNode.SiteId : childNode.AssetId} node={childNode} />
                        })}
                    </ul>
                )}
            </li>

        </>
        );
    };

    //backgroundImage:`linear-gradient(to right, #1B4256 , #007675)`
    const TreeViewVehiculos: React.FC = () => {
        return (<div className='m-2 border rounded  overflow-auto' >
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {lstVehiculosCliente.map((node: any) => {
                    if (node.subRows.length > 0)
                        return (<TreeNode key={node.SiteId} node={node} />)
                }
                )}
            </ul>
        </div>
        );
    };
    return (<>
        <BlockUi tag='div' message="Cargando información..." keepInView blocking={loading} >
            <div className="form-horizontal  mx-auto">
                <HtmlTooltipStyled placement="top" arrow title={HtmlContentTitle(Tooltips["Cerrar"].title, Tooltips["Cerrar"].content)} >
                    <Button type="button" className="bt btn-sm" variant="secondary" onClick={() => {
                        setshowObject({ ...showObject, assets: false, panelCentral: true });
                    }}>
                        Cerrar
                    </Button>
                </HtmlTooltipStyled>
                <div className="ms-3 text-center">
                    <h4 className="mb-0">Configuración de Assets Clientes Preoperacional</h4>
                    <span className="text-muted m-3">Seleccion de Assets que usarán preoperacional</span>
                </div>
            </div>
            <hr className="m-2" />
            <div className="d-flex justify-content-start gap-4" style={{ width: '60%' }}>
                <FormLabel className="fs-4 mx-4 mt-2">Cliente:</FormLabel>
                {CargaListadoClientes(datosPreoperacional, setclienteSeleccionado, clienteSeleccionado)}
                <Button onClick={() => { GuardarlistadoVehiculos() }}>Guardar</Button>
            </div>
            <hr className="m-2" />
            <div className="d-flex justify-content-start gap-4" style={{ width: '50%' }}>
                <FormLabel className="fs-4 mx-4 mt-2">Filtrar:</FormLabel>
                <Form.Control
                    type="text"
                    id="inputPassword5"
                    placeholder="Escriba Aqui.."
                    onChange={(e) => {
                        setFiltro(e.currentTarget.value);
                    }}
                />
            </div>
            <hr className="m-2" />
            <TreeViewVehiculos />
        </BlockUi>
    </>)
}