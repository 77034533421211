import { KTSVG } from "../../../../../../../_start/helpers";

type Props = {
    Titulo: string;
    Subtitulo : string;
    className: string;
    innerPadding?: string;
    color?: string;
    path?:string;
    colorPath?:string;
    onClick?:() => void;
    descripcion?:string
}

export const Indicador : React.FC<Props> = ({children , Titulo, Subtitulo, className, innerPadding = "", color ="primary", path="/media/icons/duotone/Home/Library.svg", colorPath="", onClick,descripcion}) => {

    return (
        <div style={{ overflow: 'scroll'}} className={`card ${className}`  }
      //   style={{
      //     backgroundColor: "#d1e7dd"
      // }}
        >
          {/* begin::Body */}
          <div  className={`card-body ${innerPadding}`}>
            {/* begin::Section */}
            <div  className="d-flex align-items-center">
             
              {/* begin::Title */}
              <div className="d-flex justify-content-center">
                <div
                  title={`${(descripcion== null || descripcion =="" ? Titulo: descripcion)}`}
                  className="fs-4 text-center text-gray-800 text-hover-primary fw-bolder"
                  onClick={onClick}
                >
                  {Titulo}
                </div>
                <div className="fs-7 text-muted fw-bold mt-1">{Subtitulo}</div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}
    
            {/* begin::Info */}
            {/* Para el tamaño fijo y no solo eso sino que la linea de cargando quede el mismo nivel */}
            <div className="fw-bolder text-muted pt-2 text-center" style={{height:'200px', maxHeight:'160px'}}>
              {children}
            </div>
            {/* end::Info */}
    
           
          </div>
          {/* end::Body */}
        </div>
      );
    

};