import { useEffect, useState } from "react";
import { Indicador } from "./Indicadores/Indicador";
import { ClienteDatosTablasData, ClienteDatosTablasDataTx, FiltroDashBoard, FiltroDashBoardData, GetAdminis, GetAdminisCliente, GetDetalleLista, GetFallasSeniales, GetInfoDashBoardAdminAsset, GetInfoDashBoardAdminClientes, GetInfoDashBoardAdminConductores, GetInfoDashBoardAdminTickets, GetInfoDashBoardAdminVehiculosSinTx, GetLista, GetVehiculoEspecial, GettRequerimiento, PintarTablaVehiculosConFalla, PintarTablaVehiculosConTx, PintarTablaVehiculosSinTX, ContabilizarByColorCategoria, SetRequerimiento, getClientesDash, ContabilizarRequerimientosPorCategorias, ContabilizarRequerimientos, ContabilizarDataTx, ColorRow } from "../../data/PostVentaData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
import { GetFechaServidor, formatSimpleJsonColombia, formatViewHoraMinuto, formatableJson_HHMMss, locateFormatPercentNDijitos } from "../../../../../../_start/helpers/Helper";
import { AxiosError, AxiosResponse } from "axios";
import { Button, Form, Modal } from "react-bootstrap-v5";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import moment from "moment";
import BlockUi from "@availity/block-ui";
import confirmarDialog, { errorDialog, successDialog } from "../../../../../../_start/helpers/components/ConfirmDialog";
import { CategoriasPorColorTx, ColorRows, GetEstadosTransmision, SetEstadoSyscaf } from "../../../Tx/data/Reporte";
import { ConsecutivoVisual, FiltroData, GetConfiguracion, GetRequerimientosDashboard, getTablaDetalleRequerimiento } from "../../data/Requerimientos";
import { PageTitle } from "../../../../../../_start/layout/core";
import './style/dahsboard.css';
import { useToaster, Notification } from "rsuite";
import { CamposAsset, CamposConductores, CamposEmpresas, CamposEspeciales, CamposSenial, Encabezado, RetornarEstadosFlujo, getCampos, getCamposVehiculosSinTx } from "../../data/dashboard";

import { TabNuevoRequerimientos } from "./components/TabNuevoReq";
import { GetClientesAdministradores } from "../../../../Seguridad/Administracion/data/Clientes";
import { ModalRequerimientosMasivos } from "../Requerimientos/Componentes/UpLoadRequerimientos";
import { MenuIconos } from "../../../Tx/components/Reporte/Menu/MenuIconos";
import { EsPermitido, Operaciones, PermisosOpcion } from "../../../../../../_start/helpers/Axios/CoreService";
import { Campos as CamposRequerimientos } from '../../data/Requerimientos'
import { Button as Bu, Box, IconButton, Tooltip } from "@mui/material";
import { Details } from "@mui/icons-material";
import { EncabezadoHtml } from "../Requerimientos/Componentes/Encabezado";
export default function HomePostVenta() {
    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }
    const [DetallesDatos, setDetallesDatos] = useState<any>({});
    const permisosopcion = PermisosOpcion("DashBoard");
    const operacionesPermisos = Operaciones;
    const [UsuarioSoporte, setUsuarioSoporte] = useState<any[]>([]);
    const [titulo, setTiulo] = useState<string>("Creación de requerimiento");
    const [showMasivos, setshowMasivos] = useState<boolean>(false);
    const [showRequerimientos, setshowRequerimientos] = useState<boolean>(false);
    const [Estados, setEstados] = useState<any[]>([]);
    const [DataRequerimientos, setDataRequerimientos] = useState<any[]>([]);
    const [EstadosRequerimientos, setEstadosRequerimientos] = useState<any[]>([]);
    const TipoIds = "3";
    //Esta es para tomar la cantidad de muestra de vehiculos de transmision.
    const MuestraTX = 1000;
    const [showdetalles, setshowdetalles] = useState<any>(false);
    const [show, setShow] = useState(false);
    const [showr, setShowr] = useState(false);
    const [dataTx, setDatatx] = useState<any[]>([]);
    const [TipoReporte, setTipoReporte] = useState<string>("1");
    const [dataUnidades, setDataUnidades] = useState<any[]>([]);
    const [dataSeniales, setdataSeniales] = useState<any[]>([]);
    const [dataConductores, setdataConductores] = useState<any[]>([]);
    const [TiketsDatos, setTiketsDatos] = useState<any[]>([]);
    const [DatoVehiculoEspecial, setDatoVehiculoEspecial] = useState<any[]>([]);
    const [dataEmpresas, setdataEmpresas] = useState<any[]>([]);
    const [EstadoReqSeleccionado, setEstadoRequerimientosSeleccionado] = useState<any>({});
    const [Observaciones, setObservaciones] = useState<string>("");
    const [DatosEncabezado, setDatosEncabezado] = useState<any>([]);
    const [TituloModal, setTituloModal] = useState<string>("");
    const [TituloTicket, setTituloTicket] = useState<string>("Listado de tickets por estado");
    const [TxUltimaActualizacion, setTxUltimaActualizacion] = useState<string>("");
    //indicadores de requerimientos ST.
    const [Abiertos, setAbiertos] = useState<string>("0");
    // const [Cerrados, setCerrados] = useState<string>("0");
    const [AsignadosSt, setAsignadosSt] = useState<string>("0");
    const [Ing, setIng] = useState<string>("0");
    const [EnSoporte, setEnSoporte] = useState<string>("0");
    const [RegistroSeleccionado, setRegistroSeleccionado] = useState<any>({});
    //ESTA ES PARA EL MODAL DINAMICO.
    const [sowL, setShowL] = useState<boolean>(false);
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    const [indicadores, setIndicadores] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [Cabecera, setCabecera] = useState<any>(
        {
            administrador: "",
            UsuarioAdministradorId: "",
            assetid: "",
            clienteid: "",
            registrationNumber: "",
            description: "",
            nombrecliente: "",
            agente: "",
            UsuarioId: "",
        }
    );
    var Indicadores = {
        Assets: 0,
        Clientes: 0,
        Conductores: 0,
        VehiculosSinTx: 0,
        Seniales: 0,
        Ticket: 0
    };
    const [DatosTablaIndicadoresUNICOS, setDatosTablaIndicadoresUNICOS] = useState<any[]>([]);
    var Data: any[] = [];
    const [LstSinTx, setLstSinTx] = useState<any[]>([]);
    const [LstConFalla, setLstConFalla] = useState<any[]>([]);
    const [LstConTx, setLstConTx] = useState<any[]>([]);
    const [Requerimientos, setRequerimientos] = useState<any[]>([]);

    //ESTA ES PARA EL MODAL DE BUSQUEDA DE EQUIPOS ESPECIALES.
    const [showEspecial, setshowEspecial] = useState<boolean>(false);
    const [PlacaEspecial, setPlacaEspecial] = useState<string>("");
    let Clientesseleccionados = { "ClienteId": 0, "clienteNombre": "Todas las bases" };
    let Administradorseleccionados = { "UsuarioId": 0, "Nombres": "Todos" };
    //Objeto para el uso de los clientes y administradores.
    const [ClientesData, setClientesData] = useState<any>({
        Clientes: [],
        ClientesOriginal: [],
        ClienteSeleccionado: Clientesseleccionados,
        Admins: [],
        AdminsOriginal: [],
        AdminSeleccionado: Administradorseleccionados
    })
    //para guardar los datos orignales cuando esten filtrados.
    const [DatosOrignales, setDatosOriginales] = useState<any>({
        dataUnidades: [],
        dataSeniales: [],
        dataConductores: [],
        dataTickets: [],
        dataTx: [],
        dataEmpresas: [],
        Requerimientos: []
    });

    //para guardar los datos orignales cuando esten filtrados.
    const [DatosTablas, setDatosTablas] = useState<any>({
        dataUnidades: [],
        dataSeniales: [],
        dataConductores: [],
        dataTickets: [],
        dataTx: [],
        dataEmpresas: [],
        dataTxSemaforizada: [],
    });
    //para guardar los datos orignales cuando esten filtrados.
    const [IndicadoresDatos, setIndicadoresDatos] = useState<any>({
        dataUnidades: [],
        dataSeniales: [],
        dataConductores: [],
        dataTickets: [],
        dataTx: [],
        dataEmpresas: [],
    });
    const [resultData, setresultData] = useState<any>([]);
    const [DataEstadosPorColor, setDataEstadosPorColor] = useState<any[]>([]);
    const [DataEstadosPorColorR, setDataEstadosPorColorR] = useState<any[]>([]);
    const [DatatxTabla, setDatatxTabla] = useState<any[]>([]);
    const [DataR, setDataR] = useState<any[]>([]);
    const [isShowTableReq, setisShowTableReq] = useState<boolean>(false);

    //1
    useEffect(() => {
        if (vUser != undefined)
            ConsultasIniciales();
        return () => {
            // setDataAdmin([]);
            setIndicadores([]);
            setdataConductores([]);
        }
    }, []);

    //Use  Effect para cambiar el titulo solo el titulo de los modales.
    //2
    useEffect(() => {
        setTituloModal(FiltroDashBoardData.getTituloModal(TipoReporte));
        return () => {
            //prueba
        }
    }, [TipoReporte])
    //PARA ACTUALIZAR LA TABLA DE VEHICULOS SIN TX
    //3
    useEffect(() => {
        if (dataTx.length > 0) {
            // trae a informacion de los veh'iculos que no tienen tranmision
            let SinTx = PintarTablaVehiculosSinTX(dataTx, Requerimientos, dataSeniales);
            // trae la infomracion de los vehiculos que tienen tranmision pero tienen falla
            let ConFalla = (PintarTablaVehiculosConFalla(dataTx, Requerimientos, dataSeniales))
            // trae la informacion  de los vehiculos que no presentan problemas
            let ConTx = PintarTablaVehiculosConTx(dataUnidades, SinTx, Requerimientos, ConFalla)
            setLstConFalla(ConFalla)
            setLstSinTx(SinTx)
            setLstConTx(ConTx)

        }
        //PARA CREAR UNA DATA CON CONDUCTORES, ASSETS, Y CLIENTES
        if (dataEmpresas.length > 0) {
            // setdataEmpresasTabla(FiltroDashBoardData.InformacionEmpresas(dataEmpresas, dataConductores, dataUnidades));
            let a = { ...DatosOrignales };
            a.dataEmpresas = dataEmpresas;
            a.dataConductores = dataConductores;
            a.dataUnidades = dataUnidades;
            a.dataSeniales = dataSeniales;
            a.dataTx = dataTx;
            setDatosOriginales(a);
            setIndicadoresDatos(a);
        }

        //Espacio para contabilizar los requerimientos con colores.

        return () => {
            //prueba
        }
    }, [dataTx, dataUnidades, Requerimientos, EstadosRequerimientos, dataSeniales, dataEmpresas])
    //ELIMINAR ESTE CODIGO.
    useEffect(() => {
        if (Requerimientos.length > 0 && EstadosRequerimientos.length > 0) {
            GetInfoDashBoardAdminVehiculosSinTx().then(
                (result) => {
                    setDataEstadosPorColor(ContabilizarByColorCategoria(result.data));
                    setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, false, result.data))
                    setDataR(ContabilizarRequerimientos(Requerimientos, EstadosRequerimientos, false, result.data));
                    setDatosTablaIndicadoresUNICOS(result.data);
                }).catch((e) => {
                    console.log("error", e)
                    setLoader(false);
                });



        }

    }, [Requerimientos, EstadosRequerimientos])
    useEffect(() => {

        if (dataConductores.length > 0) {
            // setdataEmpresasTabla(FiltroDashBoardData.InformacionEmpresas(dataEmpresas, dataConductores, dataUnidades));
            let a = { ...DatosOrignales };
            a.dataConductores = dataConductores;
            a.dataUnidades = dataUnidades;
            setDatosOriginales(a);
            setIndicadoresDatos(a);

        }
        return () => {
            //prueba
        }
    }, [dataUnidades, dataConductores])
    //4
    useEffect(() => {
        if (resultData.length > 0 || resultData.statusText == "OK")
            if (resultData.data.length > 0)
                ConsultasAnidadas(resultData);
        return () => {
            //prueba
        }
    }, [resultData])

    //FUNCION DE CREAR LOS REQUERIMIENTOS
    const CrearRequerimiento = (row: any) => {
        let data = (row.original == undefined ? row : row.original);
        // verificamos que el vehiculo venga sin Tx
        const isNotSinTx: boolean = (data.diffAVL == undefined);
        // se preparan los datos de la cabecera para crear el requerimiento
        const DatosCabecera = {
            administrador: vUser.Nombres,
            UsuarioAdministradorId: vUser.Id,
            assetid: data.AssetId,
            clienteid: data.ClienteId,
            registrationNumber: data.registrationNumber,
            description: data.assetsDescription,
            nombrecliente: data.clientenNombre,
            agente: null,
            UsuarioId: null,
            Fallas: (data.TFallas ?? 0),
            DiasSinTx: (data.diffAVL ?? 0),
            Categoria: (data.IsEspecial != undefined ? "Equipos Especiales" : (isNotSinTx ? data.TFallas == undefined ? "Con Tx" : "Con Falla" : "Sin Tx"))  // 
        };
        setRegistroSeleccionado(data);
        setTiulo(`Nuevo requerimiento para el vehiculo  ${data.registrationNumber}`);
        setDatosEncabezado(DatosCabecera);
        setshowEspecial(false);
        setObservaciones("");
        // si el vehiculo está sin transmitir se le deberá cambiar el estado
        // si el vehiculo no está sin tranmisión crea el requerimiento
        if (data.estado == "Operando Normalmente" || isNotSinTx) {
            setCabecera(DatosCabecera);
            setShowr(true);
            setShow(false);
        }
        else
            confirmarDialog(() => {
                const _estado = (Estados.filter((e: any) => e.estado == "En Proceso").length > 0 ? Estados.filter((e: any) => e.estado == "En Proceso")[0].estadoIdS : 7);
                setLoader(true);
                SetEstadoSyscaf((data.assetId == undefined ? data.AssetId.toString() : data.assetId.toString()), String(_estado)).then((response: AxiosResponse) => {
                    setCabecera(DatosCabecera);
                    setShowr(true);
                    setShow(false);
                    setLoader(false);
                }).catch(() => {
                    console.log("Error de cambio de estado")
                    setLoader(false);
                })
            }, `¿El Vehículo con placa ${data.registrationNumber} del Cliente ${(data.clientenNombre == undefined ? data.clienteNombre : data.clientenNombre)} se encuentra operando normalmente?`, 'Si');
    }
    //Se guardan los requirimientos
    const EnviarRequerimiento = () => {
        const Fecha = GetFechaServidor().format(formatableJson_HHMMss)
        let Obervaciones = [{
            fecha: Fecha, observacion: (Observaciones == "" ? `Se crea el requerimiento para el vehiculo 
        ${Cabecera['registrationNumber']} realizado por el administrador 
        ${Cabecera['administrador']} el dia y hora ${Fecha}` : Observaciones),
            usuario: vUser.Nombres, estado: EstadoReqSeleccionado.label
        }]
        let Campos: any = {};
        Campos["Tipo"] = "Soporte";
        Campos["Cabecera"] = JSON.stringify(Cabecera);
        Campos["Observaciones"] = JSON.stringify(Obervaciones);
        Campos["Estado"] = JSON.stringify({
            tipo: EstadoReqSeleccionado.tipo,
            label: EstadoReqSeleccionado.label,
            valor: EstadoReqSeleccionado.valor,
            color: EstadoReqSeleccionado.color,
            tipoestado: EstadoReqSeleccionado.tipoestado
        });
        confirmarDialog(() => {
            setLoader(true)
            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {
                    let Inicio = String(response.data[0].Consecutivo).substring(0, 6);
                    let Final = String(response.data[0].Consecutivo).substring(6, String(response.data[0].Consecutivo).length);
                    successDialog(`Operación Éxitosa, # requerimiento ${Inicio}-${Final}`, "");
                    let dataNotificacion: any = {};
                    dataNotificacion['UsuarioId'] = UsuarioSoporte.filter((e: any) => e.EsGestor)[0].UserId;
                    dataNotificacion['RequerimientoId'] = response.data[0].Transaccion;
                    dataNotificacion['Descripcion'] = `Hola ${UsuarioSoporte.filter((e: any) => e.EsGestor)[0].Nombres}, Estás siendo notificado porque el administrador ${vUser.Nombres} ha creado el requerimiento ${response.data[0].Consecutivo}. Por favor, revisa la información. Saludos cordiales.`;
                    dataNotificacion['NotificarCorreo'] = "false";
                    dataNotificacion['NotificarPortal'] = "true";
                    FiltroData.Notificar(dataNotificacion);
                };
                let data = [...LstSinTx];
                let Registros = data.map((val: any) => {
                    if (val.AssetId != RegistroSeleccionado.AssetId)
                        return val;
                }).filter((e) => e);
                setLstSinTx(Registros);
                setShowr(false);
                setLoader(false)
            }).catch((e: AxiosError<any>) => {
                console.log("Error ", e);
                let msj = (e.response?.data.includes("El requerimiento ya existe para ese vehiculo") ? "El requerimiento ya existe para ese vehiculo" : e.response?.statusText.toString());
                errorDialog((msj != undefined ? msj : "Error  al crear el requerimiento."), "");
                setLoader(false)
            });
        }, `¿Esta completamente seguro que  desea crear el requerimiento?`, 'Guardar');

    }
    const getVehiculoEspecial = (Placa: any) => {
        if (Placa != "") {
            setLoader(true)
            GetVehiculoEspecial(Placa).then((response: AxiosResponse<any>) => {
                setDatoVehiculoEspecial(response.data);
                setLoader(false)
            }).catch((error: AxiosError<any>) => {
                console.log(error);
                setLoader(false)
            });
        }
        else {
            toaster.push(message('error', "Datos vacios", "Por favor ingrese una placa"), {
                placement: 'topCenter'
            });
        }
    };

    //Se consultan las Configuraciones
    const getConfiguracion = (sigla: any | null) => {
        GetConfiguracion(sigla).then((response: AxiosResponse<any>) => {
            if (response.data.length != 0) {
                //Usuarios de Soporte
                let Users = FiltroData.getConfiguracionSigla(response.data, "COUSS");
                setUsuarioSoporte((Users.length != 0 ? Users[0] : []));
                //Estados de Soporte
                let States = FiltroData.getConfiguracionSigla(response.data, "OERS");
                setEstadosRequerimientos(States[0]);
                // seteamos el valor de estado para la creaci'on
                const EstadoAdmin = States[0].find((f: any) => f.tipo === "Admin" && f.valor === "1")
                if (EstadoAdmin != null) {
                    // traemos el siguiente estado
                    let Flujo = RetornarEstadosFlujo(States[0], EstadoAdmin.flujo);
                    setEstadoRequerimientosSeleccionado(Flujo[0])
                }
            }
        }).catch(({ error }) => {
            console.log("Error: ", error)
        })
    }
    //ESPACIO PARA LAS FUNCIONES DE CONSULTA
    const ConsultasIniciales = () => {
        setLoader(true)
        // traemos informacion de mocks para agilizar el desarrollo 
        //ELIMINAR

        let VehiculosSinTx: any[] = [];
        //Para guardar los datos.
        let Original = { ...DatosOrignales };
        //Para los clientes u empresas.
        GetClientesAdministradores(null).then(
            (result) => {
                Original.dataEmpresas = result.data;
                let Clientes = getClientesDash(result.data);
                let a = { ...ClientesData };
                a.Clientes = Clientes.sort();
                a.ClientesOriginal = Clientes.sort();
                setClientesData(a);
                // informacion de los vehiculos del administrador
                GetInfoDashBoardAdminAsset().then(
                    (result) => {
                        Original.dataUnidades = result.data;
                        let data = result.data;
                        //SETEO DE INDICADORES
                        Indicadores.Assets = data.length;
                        setDataUnidades(data);
                        // setDatosOriginales(Original);
                    }).catch((e) => {
                        console.log("error", e)
                        setLoader(false);
                    });
                // informacion de los conductores     
                GetInfoDashBoardAdminConductores().then(
                    (result) => {
                        let data = result.data;
                        //SETEO DE INDICADORES
                        Indicadores.Conductores = data.length;
                        Original.dataConductores = data;
                        setdataConductores(data);
                        // setDatosOriginales(Original);
                        //PARA AGRUPAR LOS CONDUCTORES
                        //setIndicadores(Indicadores);
                        setLoader(false);
                    }).catch((e) => {
                        console.log("error", e);
                        setLoader(false);
                    });

                let idtimer = setTimeout(() => {
                    setresultData(result);
                    clearTimeout(idtimer);
                }, 1000);

            }).catch((e) => {
                console.log("error", e)
                setLoader(false);
            });
        GetInfoDashBoardAdminVehiculosSinTx().then(
            (result) => {
                let data = result.data;
                setDatatxTabla(ContabilizarDataTx(result.data));
                //VEHICULOS SIN TX
                VehiculosSinTx = data ?? [];
                //Para indicar cual fue la ultima fecha de actualizacion.
                setTxUltimaActualizacion(moment(VehiculosSinTx[0].fecha).format(formatSimpleJsonColombia));
                //SETEO DE INDICADORES
                Indicadores.VehiculosSinTx = VehiculosSinTx.length;
                Original.dataTx = result.data;
                //Seteamos los valores de los vehiculos sin tx
                setDatatx(VehiculosSinTx);
                // setDatosOriginales(Original);
            }).catch((e) => {
                console.log("error", e)
                setLoader(false);
            });
        GetInfoDashBoardAdminTickets().then(
            (result) => {
                Original.dataTickets = result.data;
                let data = result.data;
                //SETEO DE INDICADORES
                Indicadores.Ticket = data.length;
                Original.dataTicket = result.data;
                // setdataTickets(data);
                setIndicadores(Indicadores);
                // setDatosOriginales(Original);
            }).catch((e) => {
                console.log("error", e)
                setLoader(false);
            });
        getConfiguracion(null);
        setDatosOriginales(Original);
        ConsultarEstados();


    };
    // Consulta los estados. de tx
    const ConsultarEstados = () => {
        GetEstadosTransmision(TipoIds).then((response: AxiosResponse<any>) => {
            setEstados(response.data);
        }).catch(({ error }) => {
            console.log(error);
        });
    }
    const ConsultasAnidadas = (result: any) => {
        //Espacio para variables a usar dentro de este procedimiento.
        Data = result.data;
        const data = result.data;
        let ClientesIds: any[] = [];
        // let datos = {...DatosOrignales};
        if (data.length > 0) {
            //LSITADO DE CLIENTES O EMPRESAS

            ClientesIds = data.map((e: any) => e.clienteIdS);
            // datos.dataEmpresas = data;
            //SETEO DE INDICADORES
            Indicadores.Clientes = data.length;
            setdataEmpresas(data);
        }
        //Obtengo los requimientos del sistema para revisar cuales vehiculos tienen algun requerimiento ya creado
        GetRequerimientosDashboard().then((response: AxiosResponse<any>) => {
            if (response.data.length != 0) {
                setRequerimientos(response.data);
            }
        }).catch(() => {
            console.log("Error de consulta de detalles listas");
        })
        //indicador de señales.
        GetFallasSeniales(ClientesIds.join()).then((result: AxiosResponse<any>) => {
            //Como tengo una consulta anidada, debo traer datos anteriores para meterlos en otros.
            let DatosCompletos = [...Data];
            DatosCompletos[0].Seniales = JSON.stringify(result.data);
            setdataSeniales(result.data);
            // datos.dataSeniales = result.data;
            //Para los indicadores adicionamos señales
            let ind: any = { ...Indicadores }
            ind['Seniales'] = result.data.length;
            Indicadores = {
                Assets: ind.Assets,
                Clientes: ind.Clientes,
                Conductores: ind.Conductores,
                VehiculosSinTx: ind.VehiculosSinTx,
                Seniales: ind.Seniales,
                Ticket: ind.Ticket
            }
            //seteamos las variables
            setIndicadores(Indicadores);
            //Cancelamos el cargando.
            // setLoader(false);
        }).catch((e) => {
            console.log("error", e);
            //Cancelamos el cargando.
            setLoader(false);
        });
        GetAdministradores();
    }
    //Para reutilizar modal de tablas.  
    const TablaDatos = (TipoData: any, show: boolean) => {
        useEffect(() => {
            return () => {
                //prueba
            }
        }, [])
        //Espacio para setear los datos s montar en la tabla
        let DataLocal: any[] = [];
        let EncabezadoLocal: MRT_ColumnDef<any>[] = [];
        switch (TipoData.TipoData) {
            case '1':
                //Transmision DATOS
                DataLocal = DatosTablas.dataTx;
                EncabezadoLocal = getCampos((e: any) => {
                    return <MenuIconos setCargando={setLoader} Data={DatosTablas} setData={setDatosTablas} row={e} Estados={Estados} place={"1"} />;
                });
                break;
            case '2':
                //Asset Datos
                DataLocal = DatosTablas.dataUnidades;
                EncabezadoLocal = CamposAsset
                break;
            case '3':
                //Señales Datos
                DataLocal = DatosTablas.dataSeniales;
                EncabezadoLocal = CamposSenial
                break;
            case '4':
                DataLocal = DatosTablas.dataConductores;
                EncabezadoLocal = CamposConductores
                break;
            case '5':
                DataLocal = DatosTablas.dataTxSemaforizada;
                EncabezadoLocal = getCampos((e: any) => {
                    return <MenuIconos setCargando={setLoader} Data={DatosTablas} setData={setDatosTablas} row={e} Estados={Estados} place={"1"} />;
                });
                break;
            default:
                EncabezadoLocal = CamposEmpresas;
                DataLocal = DatosTablas.dataEmpresas;
                break;
        }
        if (TipoData.TipoData == '1') {
            //RETORNA LO REALIZADO.
            return (
                <>
                    {(show) && (DataLocal.length != 0) && (EncabezadoLocal.length != 0) && (<MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor: ColorRows(row.original)
                            }
                        })}
                        columns={EncabezadoLocal}
                        data={DataLocal}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        initialState={
                            {
                                columnVisibility: { Categoria: false },
                                density: 'compact',
                                sorting: [
                                    {
                                        id: 'Categoria', //sort by age by default on page load
                                        desc: true,
                                    }
                                ]
                            }
                        }
                    />)}
                </>
            )
        } else {
            //RETORNA LO REALIZADO.
            return (
                <>
                    {(show) && (DataLocal.length != 0) && (EncabezadoLocal.length != 0) && (<MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            sx: {
                                backgroundColor: ColorRows(row.original)
                            }
                        })}
                        columns={EncabezadoLocal}
                        data={DataLocal}
                        enableColumnOrdering
                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        initialState={
                            {
                                density: 'compact',
                            }
                        }
                    />)}
                </>
            )
        }

    }
    //Pinta los Tickets Dinamicamente.
    const IndicadoresTickets = () => {
        let AgrupadoEstado = IndicadoresDatos.dataTickets
            .reduce((p: any, c: any) => {
                let name = c.Estado;
                p[name] = p[name] ?? [];
                p[name].push(c);
                return p;
            }, {});

        let campos = Object.entries(AgrupadoEstado).map((elem: any) => {
            return (
                <div key={elem[0]} className="row align-items-start">
                    <div title={elem[0]} style={{ cursor: 'context-menu', textAlign: 'justify' }} className="col text-truncate fs-7">
                        {elem[0]}
                    </div>
                    <div title={elem[0]} className="col">
                        <span onClick={(e) => {
                            setTiketsDatos(elem[1].filter((e: any) => e.Estado == elem[0]));
                            setTituloTicket(`Listado de tickets ${elem[0]}`)
                            setShowL(true);
                        }} className="fs-7 rounded-pill p-1 shadow btn-primary" style={{ cursor: 'pointer' }}>{elem[1].length}</span>
                    </div>
                </div>
            )
        });
        return (<>{campos}</>)
    }
    const ClickBotones = (Evento: any, Estado: any, TipoReporte: any) => {
        let dat: any = [];
        let a = { ...DatosTablas };
        switch (TipoReporte) {
            case '1':
                a.dataTx = CategoriasPorColorTx(ClienteDatosTablasDataTx(DatosOrignales.dataTx, ClientesData, Estado));
                setDatosTablas(a);
                break;
            case '2':
                a.dataUnidades = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? dataUnidades.filter((val: any) => { return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId }) : dataUnidades);
                setDatosTablas(a);
                break;
            case '3':
                a.dataSeniales = ClienteDatosTablasData(DatosOrignales.dataSeniales, ClientesData, Estado);
                setDatosTablas(a);
                break;
            case '4':
                a.dataConductores = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? DatosOrignales.dataConductores.filter((val: any) => { return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId }) : DatosOrignales.dataConductores);
                setDatosTablas(a);
                break;
            case '5':
                a.dataTxSemaforizada = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? Estado.filter((val: any) => { return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId }) : Estado);
                setDatosTablas(a);
                break;
            default:
                a.dataEmpresas = dataEmpresas;
                setDatosTablas(a);
                break;
        }
        let idTimer = setTimeout(() => {
            setShow(true);
            clearTimeout(idTimer);
        }, 1000);

    };
    //Componentes del los clientes
    const ComponenteClientes = () => {
        return (
            <Form.Select className=" h-40px w-300px " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema

                let lstClientes = ClientesData.Clientes?.filter((value: any, index: any) => {
                    return value.ClienteId === e.currentTarget.value;
                });
                //Validamos si hay un cliente seleccionado y filtramos por ese cliente
                (lstClientes.length > 0 ? FiltrarDatosClienteSeleccionado(lstClientes) : FiltrarClientes());
            }} value={ClientesData?.ClienteSeleccionado.ClienteId} aria-label="Default select example">
                <option value={0}>Todas las bases</option>
                {
                    ClientesData.Clientes?.map((element: any, i: any) => {
                        return (<option key={element.ClienteId} value={element.ClienteId}>{element.clienteNombre}</option>)
                    })
                }
            </Form.Select>
        )
    }

    const FiltrarDatosClienteSeleccionado = (lstClientes: any) => {
        let a = { ...ClientesData };
        a.ClienteSeleccionado = lstClientes[0];

        setClientesData(a);

        let Administrador = (a.AdminSeleccionado.Nombres != "Todos" ? a.AdminSeleccionado.Nombres : null);
        let b = { ...IndicadoresDatos };
        b.dataUnidades = FiltroDashBoard.getFiltroClientes(lstClientes[0].ClienteId, DatosOrignales.dataUnidades);
        b.dataSeniales = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroClientesAndAdministrador(lstClientes[0].ClienteId, DatosOrignales.dataSeniales, a.AdminSeleccionado.UsuarioId) : FiltroDashBoard.getFiltroClientes(lstClientes[0].ClienteId, DatosOrignales.dataSeniales));
        b.dataConductores = FiltroDashBoard.getFiltroClientes(lstClientes[0].ClienteId, DatosOrignales.dataConductores);
        b.dataTickets = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroClientesTicketsUsuarios(lstClientes[0].clienteNombre, DatosOrignales.dataTickets, a.AdminSeleccionado.Nombres) : FiltroDashBoard.getFiltroClientesTickets(lstClientes[0].clienteNombre, DatosOrignales.dataTickets));
        b.dataTx = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroClientesAndAdministrador(lstClientes[0].ClienteId, DatosOrignales.dataTx, a.AdminSeleccionado.UsuarioId) : FiltroDashBoard.getFiltroClientes(lstClientes[0].ClienteId, DatosOrignales.dataTx));
        b.dataEmpresas = FiltroDashBoard.getFiltroClientes(lstClientes[0].ClienteId, DatosOrignales.dataEmpresas);
        setIndicadoresDatos(b);

        setAbiertos(FiltroData.getTotalPorEstadoNegacionAdminCliente(Requerimientos, ["closed", "noopen"], true, Administrador, a.ClienteSeleccionado.ClienteId).toString());
        setEnSoporte(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Soporte", Administrador, a.ClienteSeleccionado.ClienteId).toString());
        setAsignadosSt(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "ST", Administrador, a.ClienteSeleccionado.ClienteId).toString());
        setIng(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Ing", Administrador, a.ClienteSeleccionado.ClienteId).toString());

        let data = (a.AdminSeleccionado.UsuarioId != 0 ? DatosTablaIndicadoresUNICOS.filter((val: any) => {
            return val.ClienteId == lstClientes[0].ClienteId && val.UsuarioId == a.AdminSeleccionado.UsuarioId;
        }) : DatosTablaIndicadoresUNICOS.filter((val: any) => {
            return val.ClienteId == lstClientes[0].ClienteId;
        }))
        setDataEstadosPorColor(ContabilizarByColorCategoria(data));
        setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, false, data))
        setDataR(ContabilizarRequerimientos(Requerimientos, EstadosRequerimientos, false, data));
    }
    const FiltrarClientes = () => {
        let a = { ...ClientesData };
        a.ClienteSeleccionado = Clientesseleccionados;
        setClientesData(a);

        //Para saber si hay un admin seleccionado.
        let Administrador = (a.AdminSeleccionado.Nombres != "Todos" ? a.AdminSeleccionado.Nombres : null);

        let b = { ...IndicadoresDatos };
        b.dataUnidades = DatosOrignales.dataUnidades;
        b.dataSeniales = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroadministradores(a.AdminSeleccionado.UsuarioId, DatosOrignales.dataSeniales) : DatosOrignales.dataSeniales);
        b.dataConductores = DatosOrignales.dataConductores;
        b.dataTickets = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroAdministradoresTickets(`${a.AdminSeleccionado.UsuarioId} - ${a.AdminSeleccionado.Nombres}`, DatosOrignales.dataTickets) : DatosOrignales.dataTickets);
        b.dataTx = (a.AdminSeleccionado.UsuarioId != 0 ? FiltroDashBoard.getFiltroadministradores(a.AdminSeleccionado.UsuarioId, DatosOrignales.dataTx) : DatosOrignales.dataTx);
        b.dataEmpresas = DatosOrignales.dataEmpresas;
        setIndicadoresDatos(b);

        setAbiertos(FiltroData.getTotalPorEstadoNegacionAdminCliente(Requerimientos, ["closed", "noopen"], true, Administrador, null).toString());
        setEnSoporte(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Soporte", Administrador, null).toString());
        setAsignadosSt(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "ST", Administrador, null).toString());
        setIng(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Ing", Administrador, null).toString());


        let data = (a.AdminSeleccionado.UsuarioId != 0 ? DatosTablaIndicadoresUNICOS.filter((val: any) => {
            return val.UsuarioId == a.AdminSeleccionado.UsuarioId;
        }) : DatosTablaIndicadoresUNICOS)
        setDataEstadosPorColor(ContabilizarByColorCategoria(data));
        setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, false, data))
        setDataR(ContabilizarRequerimientos(Requerimientos, EstadosRequerimientos, false, data));


    }
    //Componente de los admins
    const ComponenteAdmin = () => {
        return (
            <Form.Select className=" h-40px w-300px " onChange={(e) => {
                // buscamos el objeto completo para tenerlo en el sistema
                let lstAdmin = ClientesData.Admins?.filter((value: any, index: any) => {
                    return value.UsuarioId === e.currentTarget.value;
                });
                //Por si hay un administrador seleccionado filtro por el
                FiltarAgenteSeleccionado(lstAdmin.length > 0 ? lstAdmin : [Administradorseleccionados]);
            }} value={ClientesData?.AdminSeleccionado.UsuarioId} aria-label="Default select example">
                <option value={0}>Todos</option>
                {
                    ClientesData.Admins?.map((element: any, i: any) => {
                        return (<option key={element.UsuarioId} value={element.UsuarioId}>{element.Nombres}</option>)
                    })
                }
            </Form.Select>
        )
    }

    const FiltarAgenteSeleccionado = (lstAdmin: any) => {

        let d = { ...ClientesData };
        d.AdminSeleccionado = lstAdmin[0];
        // segun el administrador seleccionado 
        d.Clientes = (lstAdmin[0].UsuarioId == 0) ? [...d.ClientesOriginal] : [...d.ClientesOriginal.filter(
            (f: any) => f.AdminsId.split(',')?.includes(lstAdmin[0].UsuarioId))];

        /// if (lstAdmin[0].UsuarioId == 0)
        d.ClienteSeleccionado = Clientesseleccionados;
        FiltrarClientes();
        setClientesData(d);
        if (lstAdmin[0].UsuarioId == 0) {
            FiltrarAgentes();
        }

        else {
            //Para saber si hay un cliente seleccionado o no.
            let Cliente = (d.ClienteSeleccionado.ClienteId != 0 ? d.ClienteSeleccionado.ClienteId : null);
            let b = { ...IndicadoresDatos };
            b.dataSeniales = (d.ClienteSeleccionado.ClienteId != 0 ? FiltroDashBoard.getFiltroadministradoresAndClientes(lstAdmin[0].UsuarioId, DatosOrignales.dataSeniales, d.ClienteSeleccionado.ClienteId) : FiltroDashBoard.getFiltroadministradores(lstAdmin[0].UsuarioId, DatosOrignales.dataSeniales));
            b.dataTickets = (d.ClienteSeleccionado.ClienteId != 0 ? FiltroDashBoard.getFiltroAdministradoresTicketsCliente(`${lstAdmin[0].UsuarioId} - ${lstAdmin[0].Nombres}`, DatosOrignales.dataTickets, d.ClienteSeleccionado.clienteNombre) : FiltroDashBoard.getFiltroAdministradoresTickets(`${lstAdmin[0].UsuarioId} - ${lstAdmin[0].Nombres}`, DatosOrignales.dataTickets));
            b.dataTx = (d.ClienteSeleccionado.ClienteId != 0 ? FiltroDashBoard.getFiltroadministradoresAndClientes(lstAdmin[0].UsuarioId, DatosOrignales.dataTx, d.ClienteSeleccionado.ClienteId) : FiltroDashBoard.getFiltroadministradores(lstAdmin[0].UsuarioId, DatosOrignales.dataTx));
            setIndicadoresDatos(b);

            let data = (d.ClienteSeleccionado.ClienteId != 0 ? DatosTablaIndicadoresUNICOS.filter((val: any) => {
                return val.ClienteId == d.ClienteSeleccionado.ClienteId && val.UsuarioId == lstAdmin[0].UsuarioId;
            }) : DatosTablaIndicadoresUNICOS.filter((val: any) => {
                return val.UsuarioId == lstAdmin[0].UsuarioId;
            }))
            setDataEstadosPorColor(ContabilizarByColorCategoria(data));
            setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, false, data))
            setDataR(ContabilizarRequerimientos(Requerimientos, EstadosRequerimientos, false, data));


            setAbiertos(FiltroData.getTotalPorEstadoNegacionAdminCliente(Requerimientos, ["closed", "noopen"], true, d.AdminSeleccionado.Nombres, Cliente).toString());
            setEnSoporte(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Soporte", d.AdminSeleccionado.Nombres, Cliente).toString());
            setAsignadosSt(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "ST", d.AdminSeleccionado.Nombres, Cliente).toString());
            setIng(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Ing", d.AdminSeleccionado.Nombres, Cliente).toString());
        }
    }
    const FiltrarAgentes = () => {
        let d = { ...ClientesData };
        let Cliente = (d.ClienteSeleccionado.ClienteId != 0 ? d.ClienteSeleccionado.ClienteId : null);

        let b = { ...IndicadoresDatos };
        b.dataSeniales = DatosOrignales.dataSeniales;
        b.dataTickets = DatosOrignales.dataTickets;
        b.dataTx = DatosOrignales.dataTx;
        setIndicadoresDatos(b);
        setAbiertos(FiltroData.getTotalPorEstadoNegacionAdminCliente(Requerimientos, ["closed", "noopen"], true, null, Cliente).toString());
        setEnSoporte(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Soporte", null, Cliente).toString());
        setAsignadosSt(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "ST", null, Cliente).toString());
        setIng(FiltroData.getTotalPorTipoAdminCliente(Requerimientos, "Ing", null, Cliente).toString());
        setDataEstadosPorColor(ContabilizarByColorCategoria(DatosTablaIndicadoresUNICOS));
        setDataEstadosPorColorR(ContabilizarRequerimientosPorCategorias(Requerimientos, EstadosRequerimientos, false, DatosTablaIndicadoresUNICOS))
        setDataR(ContabilizarRequerimientos(Requerimientos, EstadosRequerimientos, false, DatosTablaIndicadoresUNICOS));

    }

    //Consulta el listado de admins por el cliente seleccionado.
    const GetAdministradores = () => {
        GetAdminis().then((response: AxiosResponse<any>) => {
            let b = { ...ClientesData };
            b.Admins = response.data.map((e: any) => { return { "Nombres": e.nombres, "UsuarioId": e.id } }).sort();
            b.AdminsOriginal = response.data.map((e: any) => { return { "Nombres": e.nombres, "UsuarioId": e.id } }).sort();
            setClientesData(b);
        }).catch((error: AxiosError<any>) => {
            console.log(error);
        });
    };


    let EstadosPorColorCampos = (id: any) => {
        let EstadosPorColorCampos: MRT_ColumnDef<any>[] =
            [
                {
                    accessorKey: 'Estado',
                    header: '',
                    size: 40,
                    maxSize: 40,
                },
                {
                    accessorKey: 'Total',
                    header: 'Total',
                    Cell({ cell, column, row, table, }) {
                        return <Bu onClick={(e: any) => {
                            Totales(row.original, (id == "1" ? DatatxTabla : DataR), id)
                        }} >{row.original.Total}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },
                {
                    accessorKey: 'Rojo',
                    header: 'Rojo',
                    Cell({ cell, column, row, table, }) {
                        return <Bu onClick={(e: any) => {
                            Rojos(row.original, (id == "1" ? DatatxTabla : DataR), id)
                        }} >{row.original.Rojo}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },

                {
                    accessorKey: 'Amarillo',
                    header: 'Amarillo',
                    Cell({ cell, column, row, table, }) {
                        return <Bu onClick={(e: any) => {
                            Amarillos(row.original, (id == "1" ? DatatxTabla : DataR), id)
                        }} >{row.original.Amarillo}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                },
                {
                    accessorKey: 'Verde',
                    header: 'Verde',
                    Cell({ cell, column, row, table, }) {
                        return <Bu onClick={(e: any) => {
                            Verdes(row.original, (id == "1" ? DatatxTabla : DataR), id)
                        }} >{row.original.Verde}</Bu>
                    },
                    size: 15,
                    maxSize: 15,
                }
            ];
        return EstadosPorColorCampos;
    }


    const Totales = (row: any, Data: any, id: any) => {

        let DataFltrada = row.Estado == "General" ? Data : Data.filter((val: any) => {
            return (val.estadoSyscaf != undefined ? val.estadoSyscaf : JSON.parse(val.Estado).label) == row.Estado;
        });
        //Cliente filtro
        DataFltrada = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? DataFltrada.filter((val: any) => {
            return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId;
        }) : DataFltrada);
        //Usuario Filtro
        DataFltrada = (ClientesData.AdminSeleccionado.UsuarioId != "0" ? DataFltrada.filter((val: any) => {
            return val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId;
        }) : DataFltrada);

        console.log(DataFltrada);


        if (id == "1" && row.Estado != "En Proceso") {
            setTipoReporte("5");
            if(DataFltrada.length>0)
            ClickBotones(null, DataFltrada, "5");
        } else if (id == "1" && row.Estado == "En Proceso") {
            setisShowTableReq((!isShowTableReq));
        } else {
            setDataRequerimientos(DataFltrada);
            setshowRequerimientos(true);
        }
    };

    const Rojos = (row: any, Data: any, id: any) => {
        let DataFltrada = row.Estado == "General" ? Data.filter((val: any) => {
            return (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 3
        }) : Data.filter((val: any) => {
            return (val.estadoSyscaf != undefined ? val.estadoSyscaf : JSON.parse(val.Estado).label) == row.Estado && (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 3;
        })

        //Cliente filtro
        DataFltrada = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? DataFltrada.filter((val: any) => {
            return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId;
        }) : DataFltrada);
        //Usuario Filtro
        DataFltrada = (ClientesData.AdminSeleccionado.UsuarioId != "0" ? DataFltrada.filter((val: any) => {
            return val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId;
        }) : DataFltrada);

        if (id == "1" && row.Estado != "En Proceso") {
            setTipoReporte("5");
            if(DataFltrada.length>0)
            ClickBotones(null, DataFltrada, "5")
        } else if (id == "1" && row.Estado == "En Proceso") {
            setisShowTableReq((!isShowTableReq));
        } else {
            setDataRequerimientos(DataFltrada);
            setshowRequerimientos(true);
        }
    };

    const Amarillos = (row: any, Data: any, id: any) => {
        let DataFltrada = row.Estado == "General" ? Data.filter((val: any) => {
            return (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 2
        }) : Data.filter((val: any) => {
            return (val.estadoSyscaf != undefined ? val.estadoSyscaf : JSON.parse(val.Estado).label) == row.Estado && (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 2;
        })

        //Cliente filtro
        DataFltrada = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? DataFltrada.filter((val: any) => {
            return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId;
        }) : DataFltrada);
        //Usuario Filtro
        DataFltrada = (ClientesData.AdminSeleccionado.UsuarioId != "0" ? DataFltrada.filter((val: any) => {
            return val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId;
        }) : DataFltrada);

        if (id == "1" && row.Estado != "En Proceso") {
            setTipoReporte("5");
            if(DataFltrada.length>0)
                ClickBotones(null, DataFltrada, "5")
        } else if (id == "1" && row.Estado == "En Proceso" ) {
            setisShowTableReq((!isShowTableReq))
        } else {
            setDataRequerimientos(DataFltrada);
            setshowRequerimientos((DataFltrada.length >0  ? true:false));
        }
    };

    const Verdes = (row: any, Data: any, id: any) => {
        let DataFltrada = row.Estado == "General" ? Data.filter((val: any) => {
            return ((isNaN(val.Categoria) ? val.Porc : val.Categoria) == 1 || (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 0)
        }) : Data.filter((val: any) => {
            return (val.estadoSyscaf != undefined ? val.estadoSyscaf : JSON.parse(val.Estado).label) == row.Estado && ((isNaN(val.Categoria) ? val.Porc : val.Categoria) == 1 || (isNaN(val.Categoria) ? val.Porc : val.Categoria) == 0);
        })

        //Cliente filtro
        DataFltrada = (ClientesData.ClienteSeleccionado.ClienteId != "0" ? DataFltrada.filter((val: any) => {
            return val.ClienteId == ClientesData.ClienteSeleccionado.ClienteId;
        }) : DataFltrada);
        //Usuario Filtro
        DataFltrada = (ClientesData.AdminSeleccionado.UsuarioId != "0" ? DataFltrada.filter((val: any) => {
            return val.UsuarioId == ClientesData.AdminSeleccionado.UsuarioId;
        }) : DataFltrada);

        if (id == "1" && row.Estado != "En Proceso") {
            setTipoReporte("5");
            if(DataFltrada.length>0)
            ClickBotones(null, DataFltrada, "5")
        } else if (id == "1" && row.Estado == "En Proceso") {
            setisShowTableReq((!isShowTableReq))
        } else {
            setDataRequerimientos(DataFltrada);
            setshowRequerimientos(true);
        }
    };
    const DetallesModal = (row: any) => {
        setDetallesDatos(row);
        setshowdetalles(true);
    }
    return (
        <>
            <PageTitle>Dashboard requerimientos</PageTitle>


            <BlockUi tag="div" keepInView blocking={loader ?? false} >
                <div className="row g-0 g-xl-5 g-xxl-8">
                    <div className="container">
                        <div className="row">
                            <div className="mt-5 ms-3 text-center">
                                <span className="fs-3 m-3 fw-bolder">{`Bienvenido  ${vUser.Nombres}, aquí encontrarás toda la información relevante de la semana.`} </span>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                {
                                    (EsPermitido(permisosopcion, operacionesPermisos.verAdminsPostVenta)) && (<div className="bg-secondary input-group rounded">
                                        <label className="form-label mt-4 mx-4 fw-bolder">Admin:</label>
                                        <ComponenteAdmin />
                                    </div>)
                                }

                            </div>
                            <div className="col-6">
                                <div className="bg-secondary input-group rounded">
                                    <label className="form-label mt-4 mx-4 fw-bolder">Cliente:</label>
                                    {(ClientesData.Clientes.length > 0) && (<ComponenteClientes />)}
                                </div>
                            </div>

                        </div>
                    </div>
                    {(indicadores) && (<>
                        <div className="col-xl-4 pt-5">
                            <Indicador descripcion={`Cantidad total de ${IndicadoresDatos.dataTx.length} vehiculos con problemas de transmisión y su porcentaje en relación de activos`} className=" shadow card-stretch  mb-5  mb-xxl-8" Titulo={`Transmisión (${IndicadoresDatos.dataTx.length}) ${locateFormatPercentNDijitos(isNaN(IndicadoresDatos.dataTx.length / IndicadoresDatos.dataUnidades.length) ? 0 : IndicadoresDatos.dataTx.length / IndicadoresDatos.dataUnidades.length, 2)}`}
                                Subtitulo="">
                                {/* begin::Info */}
                                <div className="fw-bolder text-muted text-center fs-6">

                                    <div className="row align-items-start">

                                        {(IndicadoresDatos.dataTx != undefined && IndicadoresDatos.dataTx.length > 0) &&
                                            (FiltroDashBoard.getAssetSinTX(IndicadoresDatos.dataTx, Estados).sort((a: any, b: any) => {
                                                return a.Orden - b.Orden;
                                            }).map((val: any, index: any) => {
                                                return <div key={index} className="d-flex justify-content-between" style={{ width: "100%" }}>
                                                    <div key={index + 1} title={val.Estado} className="fs-7  text-truncate" style={{ cursor: 'context-menu', textAlign: 'justify', width: '60%' }}>{val.Estado}: </div>


                                                    <div style={{ width: '15%' }} key={index + 2} title={val.Estado} className="fs-7 " onClick={(e) => {
                                                        setTipoReporte("1");
                                                        ClickBotones(e, val.Estado, "1");
                                                    }
                                                    }>
                                                        <span title={val.Estado} style={{ cursor: 'pointer' }} className="rounded-pill p-1 shadow btn-primary">{val.Total}</span>
                                                    </div>
                                                    <div style={{ width: '25%' }} title={val.Estado} key={index + 3} className="fs-7   m-1">

                                                        <span className="rounded-pill p-1 shadow">{locateFormatPercentNDijitos(val.Total / IndicadoresDatos.dataTx.length, 2)}</span>
                                                    </div>
                                                </div>
                                            }))}

                                    </div>

                                </div>
                                {/* end::Info */}
                            </Indicador>
                        </div>
                        <div className="col-xl-4 pt-5">
                            <Indicador onClick={() => {
                                // setTipoReporte("0");
                                // setShow(true);
                            }} descripcion="Cantidad total de vehiculos con problemas de señal y su porcentaje en relación de activos" className=" shadow card-stretch  mb-5  mb-xxl-8" Titulo={`Señales (${IndicadoresDatos.dataSeniales.length}) ${locateFormatPercentNDijitos(isNaN(IndicadoresDatos.dataSeniales.length / IndicadoresDatos.dataUnidades.length) ? 0 : IndicadoresDatos.dataSeniales.length / IndicadoresDatos.dataUnidades.length, 2)}`}
                                Subtitulo=""
                                path="/media/icons/duotone/Home/Home-heart.svg">
                                {/* begin::Info */}
                                <div className="row align-items-start">

                                    {
                                        (IndicadoresDatos.dataSeniales != undefined && IndicadoresDatos.dataSeniales.length > 0) && (FiltroDashBoard.getFallasAsset(IndicadoresDatos.dataSeniales).map((val: any, index: any) => {
                                            return <div key={index} className="d-flex justify-content-between" style={{ width: "100%" }}>
                                                <div key={index + 1} title={val.Falla} className="fs-7  text-truncate" style={{ cursor: 'context-menu', textAlign: 'justify', width: '60%' }}>{val.Falla} </div>

                                                <div title={val.Falla} key={index + 2} className="fs-7   " style={{ width: '15%' }} onClick={(e) => {
                                                    setTipoReporte("3");
                                                    ClickBotones(e, val.Falla, "3");
                                                }}>
                                                    <span title={val.Falla} style={{ cursor: 'pointer' }} className="mt-5 rounded-pill p-1 shadow btn-primary">
                                                        {val.Total}
                                                    </span>
                                                </div>
                                                <div title={val.Falla} key={index + 3} style={{ width: '25%' }} className="fs-7  rounded-pill m-1  btn-secundary">
                                                    <span title={val.Falla} className="rounded-pill p-1 shadow">{locateFormatPercentNDijitos(val.Total / IndicadoresDatos.dataSeniales.length, 2)}</span>
                                                </div>
                                            </div>
                                        }))
                                    }

                                </div>
                                {/* end::Info */}
                            </Indicador>
                        </div>
                        <div className="col-xl-4 pt-5">
                            <Indicador descripcion="Cantidad total de tickets" className="shadow card-stretch  mb-5  mb-xxl-8" Titulo={`Tickets ${(IndicadoresDatos.dataTickets != undefined ? IndicadoresDatos.dataTickets.length : 0)}`} Subtitulo="">
                                <div className="fw-bolder text-muted text-center">
                                    <div className="container">
                                        {/* Aqui los ticket Dinamicos */}
                                        <IndicadoresTickets></IndicadoresTickets>
                                    </div>
                                </div>
                            </Indicador>
                        </div>
                    </>)
                    }
                </div>
                {/* begin::Row */}
                <div className="pt-10">
                    <div className="row">
                        <div className="col-6">
                            <div className="mt-5 mx-4">
                                <h5>Requerimientos Vehículos</h5>
                                {/* Busqueda de equipos especiales */}
                                <button className="btn btn-sm btn-primary" onClick={() => {
                                    setshowEspecial(true);
                                    setDatoVehiculoEspecial([]);
                                }} title="Busqueda de equipos especiales" ><i className="bi bi-search"></i></button>
                                <>&nbsp;</>
                                {/* Carga masiva */}
                                <button className="btn btn-sm btn-warning" onClick={() => {
                                    setshowMasivos(true);
                                }} title="Carga masiva de requerimientos" ><i className="bi bi-upload"></i></button>
                            </div>
                            {<TabNuevoRequerimientos LstSintTx={LstSinTx} CrearRequerimiento={CrearRequerimiento} LstConFalla={LstConFalla} LstConTx={LstConTx} />}
                        </div>
                        <div className="col-6">
                            <div className="card shadow rounded" style={{ height: 600 }}>
                                {(DataEstadosPorColor.length) && (<MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            fontSize: 14,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={EstadosPorColorCampos("1")}
                                    data={DataEstadosPorColor}
                                    enableColumnOrdering
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableSorting={false}
                                    enableColumnActions={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact' }}
                                    enableColumnDragging={false}
                                    enableFilters
                                    enableTopToolbar={false}

                                />)}


                                {/* Espacio para tablas adicioonales  */}
                                {(isShowTableReq) && (DataEstadosPorColorR.length) && (<MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            fontSize: 14,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={EstadosPorColorCampos("2")}
                                    data={DataEstadosPorColorR}
                                    enableColumnOrdering
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableSorting={false}
                                    enableColumnActions={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact' }}
                                    enableColumnDragging={false}
                                    enableFilters
                                    enableTopToolbar={false}

                                />)}
                                {/* <div className="mt-5 mx-5">
                                    <h5>Requerimientos Servicios Técnicos</h5>
                                    <p className="text-muted">Información o solicitud realizada al área de servicio técnico.</p>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-3col-xl-3 col-md-3 col-lg-3" >
                                            <Generico className={"bg-light-success shadow"} texto={"Abiertos"} indicador={Abiertos}></Generico>
                                        </div>
                                        <div className="col-sm-3 col-xl-3 col-md-3col-lg-3">
                                            <Generico className={"bg-light-success shadow"} texto={"Soporte"} indicador={EnSoporte}></Generico>
                                        </div>
                                        <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                                            <Generico className={"bg-light-success shadow"} texto={"St"} indicador={AsignadosSt}></Generico>
                                        </div>
                                        <div className="col-sm-3 col-xl-3col-md-3 col-lg-3">
                                            <Generico className={"bg-light-success shadow"} texto={"Ing"} indicador={Ing}></Generico>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 pt-5">
                                        {(indicadores) && (
                                            <Indicador onClick={() => {
                                                setTipoReporte("0");
                                                ClickBotones(null, "val.Falla", "0");
                                            }} className="shadow card-stretch mb-5  mb-xxl-8" Titulo={`Empresa (${indicadores.Clientes})`}
                                                Subtitulo=""
                                                path="/media/icons/duotone/Home/Home-heart.svg">

                                                <div className="fw-bolder pt-8 fs-7">
                                                    <span className="d-block" style={{ cursor: 'context-menu', textAlign: 'justify' }}>Unidades : <span className="rounded-pill p-1 shadow btn-primary" style={{ cursor: 'pointer' }}
                                                        onClick={(e) => {
                                                            setTipoReporte("2");
                                                            ClickBotones(e, "Todos", "2");
                                                        }}> {IndicadoresDatos.dataUnidades.length}</span></span>
                                                    <span className="d-block pt-2" style={{ cursor: 'context-menu', textAlign: 'justify' }}>Conductores: <span className="rounded-pill p-1 shadow btn-primary" style={{ cursor: 'pointer' }}
                                                        onClick={(e) => {
                                                            setTipoReporte("4");
                                                            ClickBotones(e, "Todos", "4");
                                                        }}> {IndicadoresDatos.dataConductores.length}</span></span>
                                                </div>
                                            </Indicador>
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>




            {(show && !showr) && (<Modal show={show} onHide={setShow} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{TituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mb-5" style={{ display: (TipoReporte != "1" ? 'none' : 'block') }}>
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="text-center border border-5">
                                <label className="control-label label labels-sm">Ultima actualización</label><br />
                                <span className="mx-4 fs-3 text-muted">{TxUltimaActualizacion}</span>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        </div>
                    </div>

                    {(show) && (!showr) && (
                        <div className="table-responsive">
                            <TablaDatos show={show} TipoData={TipoReporte}></TablaDatos>
                        </div>
                    )}
                </Modal.Body>
            </Modal>)}

            {(showr && !show) && (<Modal show={showr} onHide={setShowr} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-0  p-0">
                    <div className="container">
                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="row">
                                    <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                        <div className="">
                                            <label className="mx-4 fs-6 fw-bolder">Cliente: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{DatosEncabezado.nombrecliente}</span>
                                    </div>
                                    <div className="col-sm-3 col-xl-3 col-md-3 col-lg-3">
                                        <div className="">
                                            <label className="mx-4 fs-6 fw-bolder">Placa: </label>
                                        </div>
                                        <span className="mx-4 fs-6 text-muted">{DatosEncabezado.registrationNumber}</span>
                                    </div>
                                    <div className="col-sm-5 col-xl-5 col-md-5 col-lg-5">
                                        <div className="">
                                            <label className="mx-4 fs-6 fw-bolder">Administrador (es) : </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{DatosEncabezado.administrador}</span>
                                    </div>

                                    <div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                        <div className="">
                                            <label className="mx-4 fs-6 fw-bolder">Días sin Tx: </label>
                                        </div>
                                        <span className="mx-4 fs-5 text-muted">{(DatosEncabezado.DiasSinTx == undefined ? "0" : DatosEncabezado.DiasSinTx)}</span>
                                    </div>


                                    {
                                        (DatosEncabezado.Fallas != 0) && (<div className="col-sm-4 col-xl-4 col-md-4 col-lg-4">
                                            <div className="">
                                                <label className="mx-4 fs-6 fw-bolder">Fallas : </label>
                                            </div>
                                            <span className="mx-4 fs-6 text-muted">{(DatosEncabezado.Fallas == undefined ? "0" : DatosEncabezado.Fallas)}</span>
                                        </div>)
                                    }
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="mb-3">
                                    <label className="form-label fw-bolder">Observaciones</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1"
                                        value={Observaciones}
                                        onChange={(e) => setObservaciones(e.target.value)}
                                        rows={3}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-primary" onClick={EnviarRequerimiento}>Guardar</button>
                    <button className="btn btn-sm btn-secundary" onClick={() => { setShowr(false); }}>Cancelar</button>
                </Modal.Footer>
            </Modal>)}
            {(sowL) && (TiketsDatos.length != 0) && (<Modal show={sowL} onHide={setShowL} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{TituloTicket}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(sowL) && (TiketsDatos.length != 0) && (<MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}
                        columns={Encabezado}
                        data={TiketsDatos}
                        enableColumnOrdering

                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        initialState={{ density: 'compact' }}
                    />)}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secundary" onClick={() => { setShowL(false); }}>Cancelar</button>
                </Modal.Footer>
            </Modal>)}
            {
                (showEspecial) && (<Modal show={showEspecial} onHide={setshowEspecial} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Busqueda de equipos especiales</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label className="control-label label label-sm fw-bolder">Placa</label>
                                    <div className="mb-3">
                                        <input className="input form-control input-sm" type="text" onChange={(e: any) => {
                                            setPlacaEspecial(e.target.value);
                                        }} placeholder="Placa del vehiculo especial a buscar"></input>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3 mt-7">
                                        <button className="btn btn-sm btn-warning" onClick={() => {
                                            getVehiculoEspecial(PlacaEspecial);
                                        }}><i className="bi bi-search"></i> Consultar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <MaterialReactTable
                                    localization={MRT_Localization_ES}
                                    displayColumnDefOptions={{
                                        'mrt-row-actions': {
                                            muiTableHeadCellProps: {
                                                align: 'center',
                                            },
                                            size: 100,
                                        },
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            fontSize: 14,
                                            fontStyle: 'bold',
                                            color: 'rgb(27, 66, 94)'
                                        }),
                                    }}
                                    columns={CamposEspeciales(CrearRequerimiento, 4)}
                                    data={DatoVehiculoEspecial}
                                    enableColumnOrdering
                                    enableStickyHeader
                                    enableDensityToggle={false}
                                    enablePagination={false}
                                    enableRowVirtualization
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '400px' }, //give the table a max height
                                    }}
                                    initialState={{ density: 'compact' }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-sm btn-secundary" onClick={() => { setshowEspecial(false); }}>Cancelar</button>
                    </Modal.Footer>
                </Modal>)
            }
            {/* end::Row */}
            {(showMasivos) && (<ModalRequerimientosMasivos showMasivos={showMasivos} setshowMasivos={setshowMasivos} setloader={setLoader}></ModalRequerimientosMasivos>)}
            {(showRequerimientos) && (DataRequerimientos.length != 0) && (<Modal show={showRequerimientos} onHide={setshowRequerimientos} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{"Listado de requerimientos de tx"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(showRequerimientos) && (DataRequerimientos.length != 0) && (<MaterialReactTable
                        localization={MRT_Localization_ES}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 100,
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: (theme) => ({
                                fontSize: 14,
                                fontStyle: 'bold',
                                color: 'rgb(27, 66, 94)'
                            }),
                        }}

                        columns={CamposRequerimientos}
                        data={DataRequerimientos}
                        enableColumnOrdering

                        enableStickyHeader
                        enableDensityToggle={false}
                        enablePagination={false}
                        enableRowVirtualization
                        muiTableContainerProps={{
                            sx: { maxHeight: '400px' }, //give the table a max height
                        }}
                        initialState={{ density: 'compact' }}
                        enableEditing={true}
                        editingMode="modal"
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                {/* Para mostar los detallas */}
                                <Tooltip arrow placement="left" title="Detalle de requerimientos">
                                    <IconButton onClick={() => DetallesModal(row)}>
                                        <Details className="text-primary" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />)}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-secundary" onClick={() => { setshowRequerimientos(false); }}>Cerrar</button>
                </Modal.Footer>
            </Modal>)}
            {(showdetalles) && (<Modal show={showdetalles} onHide={setshowdetalles} size={"lg"}>
                <Modal.Header className=" fw-bolder  bg-secondary rounded  m-4 d-flex justify-content-center align-items-center">
                    <Modal.Title>{`Detalles del Requerimiento ${ConsecutivoVisual(DetallesDatos.original.Consecutivo)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <EncabezadoHtml ReqSeleccionado={DetallesDatos} ></EncabezadoHtml>
                            </div>
                            <div className="col-12">
                                {(DetallesDatos.length != 0) && (showdetalles) && (
                                    getTablaDetalleRequerimiento(JSON.parse(DetallesDatos.original.Observaciones), vUser)
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn btn-sm" variant="secondary" onClick={() => setshowdetalles(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>)}


        </>
    )
}


