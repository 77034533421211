 //ESPACIO PARA LAS COLUMNAS DE LAS TABLAS

import { table } from "console";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import moment from "moment";
import { number } from "yup";
import { GetFechaServidor, formatableJson_HHMMss } from "../../../../../_start/helpers/Helper";
import { Dias, FechaCambio } from "../../Tx/data/Reporte";



    //SIN TX
  export  let Campos: MRT_ColumnDef<any>[] =
        [
            {
                accessorKey: 'clientenNombre',
                header: 'Cliente',
                enableHiding: false,
                size: 10,
                minSize: 10, //min size enforced during resizing
                maxSize: 10,

            },
            {
                accessorKey: 'registrationNumber',
                header: 'Placa',
                enableHiding: false,
                size: 10,
                minSize: 10, //min size enforced during resizing
                maxSize: 10,

            },
            {
                accessorKey: 'diffAVL',
                header: 'Ultimo AVL',
                enableHiding: true,
                size: 10,
                minSize: 10, //min size enforced during resizing
                maxSize: 10,
                Cell({ cell, column, row, table, }) {
                    return moment(row.original.AVL).format("DD/MM/YYYY");
                }
            },
            {
                accessorKey: 'estadoSyscaf',
                header: 'Estado',
                enableHiding: false,
                size: 10,
                minSize: 10, //min size enforced during resizing
                maxSize: 10,
                Cell({ cell, column, row, table, }) {
                    return RetornarEstado(row.original.estado);
                }
            }
        ];
    
    
    
   //ESPECIAL
  export let CamposEspeciales =  (CrearRequerimiento: (arg0:any) => void , tipo:number) =>{
    let CampoEspecial : MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clientenNombre',
            header: 'Cliente',
            enableHiding: false,
            size: 10,
            minSize: 10, //min size enforced during resizing
            maxSize: 10,

        },
        {
            accessorKey: 'registrationNumber',
            header: 'Placa',
            enableHiding: false,
            size: 10,
            minSize: 10, //min size enforced during resizing
            maxSize: 10,

        },
        {
            accessorKey: 'estadoSyscaf',
            header: 'Estado',
            enableHiding: false,
            size: 10,
            minSize: 10, //min size enforced during resizing
            maxSize: 10,
            Cell({ cell, column, row, table, }) {
                return RetornarEstado(row.original.estadoSyscaf);
            }
        },
        {
            accessorKey: 'Sitio',
            header: 'Sitio',
            enableHiding: false,
            size: 10,
            minSize: 10, //min size enforced during resizing
            maxSize: 10,
        },
        {
            accessorKey: 'Id',
            header: '',
            Cell({ cell, column, row, table, }) {
                return (
                    <span className="float-end">
                        <a
                            onClick={() => CrearRequerimiento(row)}
                            className="btn btn-primary btn-sm fw-bolder"
                            title={`Creación de requerimiento para el vehiculo ${row.original.registrationNumber}`}
                        >
                            <i className="bi-clipboard-check"></i>
                        </a>
                    </span>)
            },
            size: 5,
            minSize: 5, //min size enforced during resizing
            maxSize: 5,
            enableColumnFilter :false
        }
    ]
    return CampoEspecial;
};
 //SIN TX
 export let getCampos =  ( MenuIconos: (arg0:any) => void ) => {
    let Campos: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'accion',
            header: 'Acciones',
            Cell({ cell, column, row, table, }) {
              return <div className="">{MenuIconos(row.original.assetId)}</div>
            },
            size: 200,
          },
        {
            accessorKey: 'clienteNombre',
            header: 'Cliente',
            enableHiding: false,
            size: 100,
        },
        {
            accessorKey: 'registrationNumber',
            header: 'Placa',
            enableHiding: false,
        },
        {
            accessorKey: 'diffAVL',
            header: 'Ultimo AVL',
            enableHiding: true,
            Cell({ cell, column, row, table, }) {
                return moment(row.original.AVL).format("DD/MM/YYYY");
            }
        },
        {
            accessorKey: 'estadoSyscaf',
            header: 'Estado',
            enableHiding: false,
            Cell({ cell, column, row, table, }) {
                return RetornarEstado(row.original.estadoSyscaf);
            }
        },
        {
          accessorKey: 'ConfiguracionEstado',
          header: 'Dias transcurridos',
          enableHiding: true,
          Cell({ cell, column, row, table, }) {
             return Dias(row.original);
          },
        },
        {
          accessorKey: 'FechaCambio',
          header: 'F. Cambio Estado',
          enableHiding: true,
          Cell({ cell, column, row, table, }) {
             return FechaCambio(row.original);
          },
        },
          {
              accessorKey: 'Categoria',
              header: "Categoria",
              enableHiding: true,
          }
    ];
    return Campos;
}
//VEHICULOS
export   let CamposAsset: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'Base',
            header: 'Cliente',
            enableHiding: false,
        },
        {
            accessorKey: 'Description',
            header: 'Descripción',
            enableHiding: false,
        },
        {
            accessorKey: 'RegistrationNumber',
            header: 'Placa',
            enableHiding: false,
        },
    ];
//SEÑALES
export   let CamposSenial: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clienteNombre',
            header: 'Cliente',
            enableHiding: false,
        },
        {
            accessorKey: 'assetsDescription',
            header: 'Placa',
            enableHiding: false,
        },
        {
            accessorKey: 'TFallas',
            header: 'Cantidad de veces',
            enableHiding: true,
        },
        {
            accessorKey: 'Falla',
            header: 'Nombre',
            enableHiding: false,
        },
        {
            accessorKey: 'TotalFallas',
            header: 'Total ocurrencia',
            enableHiding: false,
        }
    ];
//CONDUCTORES
export  let CamposConductores: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clienteNombre',
            header: 'Cliente',
            enableHiding: false,
        },
        {
            accessorKey: 'name',
            header: 'Nombre',
            enableHiding: false,
        },
    ];
//TICKETS
export  let Encabezado: MRT_ColumnDef<any>[] =
    [{
        accessorKey: 'TicketId',
        header: 'TicketId',
        enableHiding: false,
    },
    {
        accessorKey: 'Administrador',
        header: 'Administrador',
        enableHiding: false,
        Cell({ cell, column, row, table, }) {
            let Nombre = (row.original.Administrador != undefined ? (row.original.Administrador.split(" - ")[1] == undefined ? row.original.Administrador.split(" - ")[0] : row.original.Administrador.split(" - ")[1]) : "No admin");
            return Nombre;
        },
    },
    {
        accessorKey: 'Base',
        header: 'Cliente',
        enableHiding: false,
    },
    {
        accessorKey: 'Ticket',
        header: 'Descripción',
        enableHiding: true,
    },
    {
        accessorKey: 'TipodeTicket',
        header: 'Tipo',
        enableHiding: true,
    },

    ];
//CAMPOS EMPRESAS O CLIENTES
export let CamposEmpresas: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clienteNombre',
            header: 'Nombre',
            enableHiding: false,
        }
    ];
//CAMPOS VEHICULOS SIN TX
export let getCamposVehiculosSinTx =  ( CrearRequerimiento: (arg0:any) => void , tipo:number) =>{
    let CamposVehiculosSinTX: MRT_ColumnDef<any>[] =
    [
        {
            accessorKey: 'clientenNombre',
            header: 'Cliente',
            Cell({ cell, column, row, table, }) {
                let cliente = (row.original.clientenNombre == undefined ? row.original.clienteNombre : row.original.clientenNombre);
                return cliente.replace('Col FV Syscaf', '');
            },
            size:50,
            enableColumnFilter :true
        },
        {
            accessorKey: 'registrationNumber',
            header: 'Placa',
            size:50,
            Cell({ cell, column, row, table, }) {
                let DiasSinTX = (row.original.diffAVL == undefined ? row.original.DiasSinTx : row.original.diffAVL);
                return <span className="label labels-sm control-label" title={`El vehiculo ${row.original.registrationNumber} tiene ${DiasSinTX} sin tx`}>{row.original.registrationNumber}</span>;
            },
        },
        {
            accessorKey: 'diffAVL',
            header: 'Días',
            size:50,
            enableHiding: false,
            Cell({ cell, column, row, table, }) {
                let DiasSinTX = (row.original.diffAVL == undefined ? row.original.DiasSinTx : row.original.diffAVL);
                return <span className="label labels-sm control-label" title={`El vehiculo ${row.original.registrationNumber} tiene ${DiasSinTX} sin tx`}>{DiasSinTX}</span>;
            },
            enableColumnFilter :false
        },
        {
            accessorKey: 'TFallas',
            header: 'Fallas',
            enableHiding: false,
            Cell({ cell, column, row, table, }) {
                let DiasSinTX = (row.original.diffAVL == undefined ? row.original.DiasSinTx : row.original.diffAVL);
                return <span className="label labels-sm control-label" title={`El vehiculo ${row.original.registrationNumber} tiene ${DiasSinTX} sin tx   ${(row.original.TFallas != 0 ? `y tiene ${row.original.TFallas} fallas de señal` : "")} `}>{row.original.TFallas}</span>;
            },
            size:50,
            enableColumnFilter :false
        },
        {
            accessorKey: 'Id',
            header: '',
            size:50,
            Cell({ cell, column, row, table, }) {
                return (
                    <span className="float-end">
                        <a
                            onClick={() => CrearRequerimiento(row)}
                            className="btn btn-primary btn-sm fw-bolder"
                            title={`Creación de requerimiento para el vehiculo ${row.original.registrationNumber}`}
                        >
                            <i className="bi-clipboard-check"></i>
                        </a>
                    </span>
                )
            },
            enableColumnFilter :false
        }
    ];
    
    CamposVehiculosSinTX.slice(2,tipo);
  

    return CamposVehiculosSinTX;
}


 //PARA LOS COLORES EN LOS ESTADOS
 export const RetornarEstado = (Estado: any) => {
    return (
        <>{(Estado == "Sin Respuesta del Cliente" ? <span className='badge bg-warning'>{Estado}</span> : (Estado == "En Mantenimiento" ? <span className='badge bg-info'>{Estado}</span> : (Estado == "Detenido" ? <span className='badge bg-danger'>{Estado}</span> : <span className='badge bg-success'>{Estado}</span>)))}</>
    )
}

// funcion para traer el o  ;los estados de los flujos
export const RetornarEstadosFlujo = (Estados: any[], ids :any []) => {
   
    return Estados.filter( f=> ids.find( fnd => fnd == f.valor) != null )
}

// funcion para traer el o  ;los estados de los flujos
export const RetornarFlujo = (Estados: any[], Estado :any) => {
    const filtro = Estados.filter( f=> f.valor == Estado.valor )
   
    return  (filtro.length == 0) ? [] : filtro[0].flujo;
}

export const RetornarRequerimientosMasivos = (RequerimientosValidados:any[], vUser:any) =>{
    const Fecha = GetFechaServidor().format(formatableJson_HHMMss)
    let Campos:any = {};
    Campos["Tipo"] = "Soporte";
    Campos = RequerimientosValidados.map((a:any,sb:any) =>{
        let campo:any = {};
        let Cabecera:any = {};
        Cabecera['administrador'] =  vUser.Nombres;
        Cabecera['UsuarioAdministradorId'] =  vUser.Id;
        Cabecera['assetid'] = a.AssetId;
        Cabecera['clienteid'] = a.ClienteId;
        Cabecera['registrationNumber'] = a.registrationNumber;
        Cabecera['description'] =  a.assetsDescription;
        Cabecera['nombrecliente'] = a.clientenNombre;
        Cabecera['agente'] = null;
        Cabecera['UsuarioId'] = null;
        Cabecera['Fallas'] = (a.TFallas ?? 0);
        Cabecera['DiasSinTx'] = (a.diffAVL ?? 0);
        Cabecera['Categoria'] =  "Con Tx";
        let Obervaciones = [{
            fecha: Fecha, observacion: (a.Observaciones == "" ? `Se crea el requerimiento para el vehiculo 
        ${Cabecera['registrationNumber']} realizado por el administrador 
        ${Cabecera['administrador']} el dia y hora ${Fecha}` : a.Observaciones),
            usuario: vUser.Nombres, estado: "Sin Asignar"
        }];
        campo["Cabecera"] = JSON.stringify(Cabecera);
        campo["Observaciones"] = JSON.stringify(Obervaciones);
        campo["Estado"] = JSON.stringify({
            tipo: "Soporte",
            label:"Sin Asignar",
            valor: "3",
            color:"info",
            tipoestado:"noopen"
        });
        campo["AssetId"] = a.AssetId
        campo["FechaCreacion"] = Fecha;

       return campo;
    });
    return Campos;

}