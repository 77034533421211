import BlockUi from "@availity/block-ui";
import { useEffect, useState } from "react";
import { PageTitle } from "../../../../../../_start/layout/core";
import {
    ActualizaDatosRequerimientos, Campos, CamposReporte, ConsecutivoVisual,
    Detalles, FiltroData, GetDatosRequerimientos, GetObservaciones,
    IndicadoresAdmin, IndicadoresGenerales, NotificarRequerimiento, ObjetoEjecucionOrden,
    SetRequerimiento, listTabsRequerimientos, totalesTabs
} from "../../data/Requerimientos";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { CategoriasPorColor, ColorRow, DiasCambios, FechaCambio, FiltroDashBoardData } from "../../data/PostVentaData";
import { AxiosResponse } from "axios";
import { useToaster, Notification } from "rsuite";
import { Box, IconButton, Tooltip } from "@mui/material";
import { AdjustRounded, Assignment, AttachFile, BorderOuter, Engineering, Equalizer, ListAlt, Message, RequestPage, ViewAgenda } from "@mui/icons-material";
import { DescargarExcel } from "../../../../../../_start/helpers/components/DescargarExcel";
import { useSelector } from "react-redux";
import { UserModelSyscaf } from "../../../../auth/models/UserModel";
import { RootState } from "../../../../../../setup";
import confirmarDialog from "../../../../../../_start/helpers/components/ConfirmDialog";
import CreacionSt from "./CreacionSt";
import { ModalDetalles } from "./Componentes/Modales";
import { DeserializarJSon } from "../../../../../../_start/helpers/Helper";
import { NotificacionSyscaf } from "../../../../../../_start/helpers/components/NotificacionSonora";
import { SortingState } from "@tanstack/react-table";
import { ModalEjecucionOrden } from "./Componentes/Modales/ModalEjecucionOs";
import { ConsultasIniciales, useDataRequerimientos } from "../../core/RequerimientosCore";
import { ModalSoporteDLP } from "./Componentes/Modales/ModalDLP";
import { ModalDiagnostico } from "./Componentes/Modales/ModalDiagnostico";
import { ModalEditarReq } from "./Componentes/Modales/ModalEditarReq";
import { ModalEditarProgramacion } from "./Componentes/Modales/ModalEditarProgramacion";
import { ModalAsignacion } from "./Componentes/Modales/ModalAsignacion";
import { Adjunto } from "./Componentes/Modales/Adjunto";
import moment from "moment";


export default function CreacionCentral() {
    const user = useSelector<RootState>(
        ({ auth }) => auth.user
    );
    const vUser = user as UserModelSyscaf;
    //INICIO ESPACIO CONSTANTES
    const { lstUsuarios: Usuarios, UserActual, lstRequerimientos, setlstRequerimientos, perfil,
        lstEstadosReq: EstadosRequerimientos, setReqSeleccionado, ReqSeleccionado, ListadoDLPRespuesta
    } = useDataRequerimientos();

    const toaster = useToaster();
    const message = (type: any, titulo: string, mensaje: React.ReactNode) => {
        return (<Notification className="bg-light-danger" type={type} header={titulo}
            closable duration={10000}>
            {mensaje}
        </Notification>)
    }

const [showAdjuntoModal, setshowAdjuntoModal] = useState<boolean>(false);
    //ESPACIO PARA LAS CONST
    const [loader, setloader] = useState<boolean>(false);

    //Constante para el modal de ST
    const [ShowSt, setShowSt] = useState<boolean>(false);
    //Para saber cual es la que viene desde la tabla o DB

    const [tabSel, settabSel] = useState<number>(0);
    const [ReqFiltrados, setReqFiltrados] = useState<any[]>([]);

    const [show, setshow] = useState<boolean>(false);
    //const [ShowFiltros, setShowFiltros] = useState<boolean>(false);
    const [showedit, setshowedit] = useState<boolean>(false);
    const [ShowAsignacion, setShowAsignacion] = useState<boolean>(false);
    const [ShowModalDiag, setShowModalDiag] = useState<boolean>(false);
    const [ModalDLP, setModalDLP] = useState<boolean>(false);
    const [InfDiag, setInfDiag] = useState<any[]>([]);
    const [InfObs, setInfObs] = useState<any>("");

    // const [AgentesSeleccionado, setAgentesSeleccionado] = useState<any>({ "Agente": "Todos", "UsuarioId": "Todos" });
    // const [ClienteSeleccionado, setClienteSeleccionado] = useState<any>({ "Cliente": "Todos", "ClienteId": "Todos" });
    // const [EstadoSeleccionado, setEstadoSeleccionado] = useState<any>({ "Estado": "Todos" });

    // const EstadoStEnProgreso =  EstadosGOI.EstadoStEnProgreso;
    /*============================================================================================================================================================================== */
    /** ESpacio para los tipos de estados a usar por el momento usare estos porque fueron los que se habian definido si en un posterior evento se dinamiza cambiar por estos.        */
    /*============================================================================================================================================================================== */
    const [DataEjecucion, setDataEjecucion] = useState<any>({});
    const [ShowModalOrden, setShowModalOrden] = useState<boolean>(false);
    const [TotaleGeneral, SetTotaleGeneral] = useState<any>({ "Todos": 0, "Asignados": 0 });

    //FIN ESPACIO CONSTANTES
    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);




    //ESPACIO PARA LA FUNCION QUE APLICA LOS FILTROS
    const FiltroDatos = (data: any[]) => {
        // datos traidos del c liente
        let datosfiltrados: any = data;

        // filtramos por las fechas
        //  datosfiltrados = datosfiltrados.filter((f: any) => moment(f.Periodo).toDate() >= FechaInicial && moment(f.Periodo).toDate() <= FechaFinal);
        //FILTRA POR USUARIOS O AGENTES
        // if (AgentesSeleccionado.UsuarioId != undefined && AgentesSeleccionado.UsuarioId != "Todos")
        //     datosfiltrados = FiltroData.getFiltrobyAgente(datosfiltrados, AgentesSeleccionado.UsuarioId);
        // //PARA APLICAR FILTRO DE ESTADOS
        // if (EstadoSeleccionado.Estado != undefined && EstadoSeleccionado.Estado != "Todos")
        //     datosfiltrados = FiltroData.getFiltrobyEstados(datosfiltrados, EstadoSeleccionado.Estado);
        // if (ClienteSeleccionado.ClienteId != undefined && ClienteSeleccionado.ClienteId != "Todos")
        //     datosfiltrados = FiltroData.getFiltrobyCliente(datosfiltrados, ClienteSeleccionado.ClienteId);

        // seteamos la info filtrada 
        setReqFiltrados(CategoriasPorColor(datosfiltrados, EstadosRequerimientos))
    }
    //=======================FIN ESPACIO PARA CONSULTAS INICIALES ===================
    //ESPACIO PARA LOS USEEFFECT

    useEffect(() => {

        if (lstRequerimientos.length > 0) {
            FiltroDatos(lstRequerimientos);
            const totales = totalesTabs(lstRequerimientos, TotaleGeneral);
            if (Object.keys(TotaleGeneral).length > 0) {
                Object.entries(TotaleGeneral).forEach((f) => {

                    if (totales[f[0]] != f[1])
                        var idTimeout = setTimeout(() => {
                            toaster.push(NotificacionSyscaf({
                                includeSonido: true, duration: 3500, closable: true,
                                type: "warning", content: `Usted tiene ${totales[f[0]]} Requerimientos disponibles`, header: `Requerimientos`
                            }), {});
                            clearTimeout(idTimeout);
                        }, 1000);

                });


            }

            SetTotaleGeneral(totales);
        }

    }, [lstRequerimientos])
    // A ver si funciona

 

    //Detalles del modal.
    const DetallesModal = (row: any) => {
        setReqSeleccionado(row);
        setshow(true);
    }
    //PARA QUE PUEDAN SELECCIONAR UN AGENTE DIFERENTE Y NUEVO.
    const EditarRequerimiento = (row: any) => {
        setReqSeleccionado(row); // seteamos el requerimiento seleccionado
        // revisamos el estado actual y los estados a los que ira
        const EstadoActual: any = DeserializarJSon(row.original.Estado);
        if (EstadoActual.tipoestado === "os")
            setShowModalOrden(true);

        else setshowedit(true);
    };

    //Para Crearlo y enviarlo al servidor
    const GuardarObservaciones = (Observaciones: string) => {
        const EstadoActual = DeserializarJSon(ReqSeleccionado.original.Estado);
        const Cabecera = DeserializarJSon(ReqSeleccionado.original.Cabecera);

        let _obs = DeserializarJSon(ReqSeleccionado.original.Observaciones);

        if (Observaciones != "")
            GetObservaciones(_obs, Observaciones, EstadoActual, vUser, false, false);
        else
            toaster.push(message('error', "Nueva Observación", "El campo no puede estar vacío"), {
                placement: 'topCenter'
            });

        // obtenemos la estructura a guardar de los requerimientos
        let Campos = GetDatosRequerimientos(ReqSeleccionado.original.Id, Cabecera, _obs, EstadoActual);

        confirmarDialog(() => {
            setloader(true)
            SetRequerimiento(Campos).then((response: AxiosResponse<any>) => {
                if (response.statusText == "OK") {

                    setshowedit(false);
                    // actualizamos el array para no tener que volver al servidor 
                    let data: any = ActualizaDatosRequerimientos(ReqFiltrados, ReqSeleccionado, Campos);
                    ConsultasIniciales(setloader, vUser, setlstRequerimientos, toaster); //ACTUALIZAR DE FORMA LOCAL Y NO VOLVER A LLAMAR
                    // FiltroDatos(ReqFiltrados);
                    NotificarRequerimiento(Cabecera.UsuarioId, ReqSeleccionado.original.Id, `Nuevo mensaje,Usuario: ${vUser.Nombres},Mensaje : ${Observaciones}`);
                    setloader(false);
                    setReqSeleccionado({ ...ReqSeleccionado, Observaciones: JSON.stringify(_obs) });
                    toaster.push(message('success', "Nueva Observación", "Adicionado Correctamente"), {
                        placement: 'topCenter'
                    });

                }
            }).catch(({ error }) => {
                console.log("Error", error)
                setshowedit(false);
            });
        }, `¿Esta seguro que desea agregar información al registro?`, 'Guardar');
    }


    // FUNCION QUE PREPARA LA INFORMACION PARA LA INTERFAZ DE ASINGACION DE REQUERIMIENTOS
    const Asignacion = (row: any) => {
        // guarda el valor orgiginal del registo seleccionado
        setReqSeleccionado(row); // ROW SELECCIONADO PARA MEJOR MANEJO DE INFORMACION EN EL MODAL

        setShowAsignacion(true); // MUESTRA EL MODAL

    }


    //Muestra el diggnostico de requerimiento
    const VerDiagnosticoDLP = (row: any) => {
        setReqSeleccionado(row); //seteamos el requerimiento seleccionado
        let _Diagnostico: any[] = [];
        //   ObsDiagnostico
        let obs = DeserializarJSon(row.original.Observaciones);
        _Diagnostico = JSON.parse(row.original.Diagnostico);
        _Diagnostico = _Diagnostico.map((f: any) => {
            return (f.Respuesta ? f : undefined);
        }).filter((e: any) => e);
        setInfDiag(_Diagnostico);
        let ObsDLP = obs.filter((e: any) => { return e.Esdlp });
        setInfObs((ObsDLP.length != 0 ? ObsDLP[0].observacion : obs[obs.length - 1].observacion)); // setea las observaciones
        setShowModalDiag(true);

    }
    const [showModalProg, setShowModalProg] = useState<boolean>(false);
    //Muestra el diggnostico de requerimiento
    const EditarProgramacion = (row: any) => {
        setReqSeleccionado(row); //seteamos el requerimiento seleccionado
        setShowModalProg(true);

    }
    //Para aplicar los filtros de columnas por partes
    const FiltroPorColumna = (row:any, id:any, filterValue:any) =>{
        let estado = JSON.parse(row.original.Estado);
        let resultado: any;
        let Cabecera = JSON.parse(row.original.Cabecera);
        let Fecha = FechaCambio(row.original);
        let Dias = DiasCambios(row.original);

        switch(id) {
            case 'Zona':
                resultado = (estado.tipo.toLowerCase().includes(filterValue.toLowerCase()))
                break;
            case 'Consecutivo':
                resultado = (row.original.Consecutivo == filterValue)
                break;
            case 'Categoria':
                resultado = (Cabecera.Categoria.toLowerCase().includes(filterValue.toLowerCase()))
            break;
            case 'nombrecliente':
                resultado = (Cabecera.nombrecliente.toLowerCase().includes(filterValue.toLowerCase()))
            break;
            case 'registrationNumber':
                resultado = (Cabecera.registrationNumber.toLowerCase().includes(filterValue.toLowerCase()))
            break;
            case 'Estado':
                resultado = (estado.label.toLowerCase().includes(filterValue.toLowerCase()))
            break;
            case 'Administrador':
                resultado = (Cabecera.administrador.toLowerCase().includes(filterValue.toLowerCase()))
            break;
            case 'agente':
                resultado = ((Cabecera.agente != null ?  Cabecera.agente.toLowerCase().includes(filterValue.toLowerCase()) : Cabecera.agent))
            break;
            case 'Fecha':
                let fecha = moment(row.original.FechaCreacion).format("DD/MM/YYYY HH:MM").toString();
                resultado = ((row.original.FechaCreacion != undefined ? fecha.includes(filterValue) :row.original.FechaCreacion))
            break;
            case 'Porc':
                resultado = (row.original.Porc == filterValue)
            break;
            case 'FechaCambio':
                let FechaCambio = moment(Fecha).format("DD/MM/YYYY HH:MM").toString();
                resultado = (FechaCambio.includes(filterValue))
            break;
            default:
                resultado = (Dias.includes(filterValue))
                break;
            }
            return resultado
    }

    //Para montar la tabla tab 1, 2, 3,4
    function MontarTabla() {
        let DatosTabla: any[] = [];
        switch (tabSel) {
            case 0: DatosTabla = FiltroData.getTodos(ReqFiltrados);
                break;
            case 1: DatosTabla = FiltroData.getAsignados(ReqFiltrados);
                break;
            case 2: DatosTabla = FiltroData.getNoAsignados(ReqFiltrados);
                break;
            case 3: DatosTabla = FiltroData.getRequerimientosPorColor(ReqFiltrados, EstadosRequerimientos, 3);
                break;
            case 4: DatosTabla = FiltroData.getRequerimientosPorColor(ReqFiltrados, EstadosRequerimientos, 2);
                break;
            case 5: DatosTabla = FiltroData.getRequerimientosPorColor(ReqFiltrados, EstadosRequerimientos, 1);
                break;

        }


        return (
            <MaterialReactTable
                localization={MRT_Localization_ES}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 200,
                    },
                }}

                onGlobalFilterChange={setGlobalFilter}

                onSortingChange={setSorting}
                muiTableHeadCellProps={{
                    sx: () => ({
                        fontSize: 14,
                        fontStyle: 'bold',
                        color: 'rgb(27, 66, 94)'
                    }),
                }}
                columns={Campos}
                data={DatosTabla}
                enableColumnOrdering
                enableStickyHeader
                enableDensityToggle={false}
                enableSorting={true}
                enablePagination={false}
                enableRowVirtualization
                muiTableContainerProps={{
                    sx: { maxHeight: '400px' }, //give the table a max height
                }}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        backgroundColor: ColorRow(row.original, EstadosRequerimientos)
                    }
                })}
                filterFns={{
                    customFilterFn:  (row, id, filterValue) => {
                       return  FiltroPorColumna(row, id,filterValue );
                    },
                  }}
                enableEditing={true}
                editingMode="modal"
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex' }}>
                        {/* Para mostar los detallas */}
                        <Tooltip arrow placement="left" title="Detalle de requerimientos">
                            <IconButton onClick={() => DetallesModal(row)}>
                                <ListAlt className="text-primary" />
                            </IconButton>
                        </Tooltip>
                        {/* Permite asignarlo siempre y cuando este en estado creado sino no lo asigna a soporte*/}
                        {(!perfil.isAdmin) && (!['closed'].includes(JSON.parse(row.original.Estado).tipoestado)) &&
                            (<Tooltip arrow placement="right" title="Asignar requerimiento">
                                <IconButton onClick={() => {
                                    Asignacion(row);
                                }}>
                                    <Assignment className="text-warning" />
                                </IconButton>
                            </Tooltip>)}
                        {/* Para editar si cumple con la condicion */}
                        {(!perfil.isAdmin) && (!["progress", "os", "closed", "noopen"].includes(JSON.parse(row.original.Estado).tipoestado)) &&
                            (<Tooltip arrow placement="top" title={"Edición o ingresar observación"}>
                                <IconButton onClick={() => EditarRequerimiento(row)}>
                                    <Message className="text-info" />
                                </IconButton>
                            </Tooltip>)}
                        {/*Permite realizar el dlp */}
                        {((perfil.isAgent || perfil.isSuperAdmin)
                            && (JSON.parse(row.original.Estado).tipo === "Soporte" &&
                                JSON.parse(row.original.Estado).tipoestado === "progress")
                        )
                            && (<Tooltip arrow placement="top" title="Realizar DLP">
                                <IconButton onClick={() => AbrirModalCheckDLP(row)}>
                                    {/* <i className="text-success bi-border-style"></i> */}
                                    <BorderOuter className="text-success" />
                                </IconButton>
                            </Tooltip>)
                        }
                        {/* Permite editar la programacion del requerimiento */}
                        {(JSON.parse(row.original.Estado).valor === '40' && row.original.Cabecera.indexOf('Tecnico') != -1)
                            && (<Tooltip arrow placement="top" title="Editar programacion">
                                <IconButton onClick={() => EditarProgramacion(row)}>
                                    <Engineering className="text-danger" />
                                </IconButton>
                            </Tooltip>)}
                        {/* Permite ver el diagnostico del requerimiento */}
                        {(row.original.Diagnostico != null)
                            && (<Tooltip arrow placement="top" title="Ver DLP de requerimiento">
                                <IconButton onClick={() => VerDiagnosticoDLP(row)}>
                                    <ViewAgenda className="text-info" />
                                </IconButton>
                            </Tooltip>)}


                        {
                            (!perfil.isAdmin) && (JSON.parse(row.original.Estado).tipo === "ST" && JSON.parse(row.original.Estado).tipoestado === "os") && (
                                <Tooltip arrow placement="right" title="Orden Servicio en Ejecución">
                                    <IconButton onClick={() => {
                                        EditarRequerimiento(row)
                                    }}>
                                        <RequestPage className="text-primary" />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        {( 
                            EstadosRequerimientos.filter((val:any) =>{
                                return val.label == JSON.parse(row.original.Estado).label
                            })[0].PermiteAdjunto === true )
                            && (<Tooltip arrow placement="top" title="Adjuntar Imagen">
                                <IconButton onClick={() => {
                                     setReqSeleccionado(row);
                                    setshowAdjuntoModal(true);
                                }}>
                                    <AttachFile className="text-danger" />
                                </IconButton>
                        </Tooltip>)}
                    </Box>
                )
                }
                state={{


                    globalFilter,

                    sorting
                }}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{ justifyContent: 'flex-end', alignItems: 'center', flex: 1, display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <button title="Reporte a excel" style={{ display: (tabSel <= 2) ? "inline-block" : "none" }} className="  btn btn-sm btn-primary" type="button" onClick={() => {
                            let data = DatosTabla.map((val: any) => {
                                let cab = JSON.parse(val.Cabecera);
                                let estado = (FiltroDashBoardData.EsJson(val.Estado) == true ? JSON.parse(val.Estado) : val.Estado)
                                return {
                                    "nombrecliente": cab.nombrecliente,
                                    "Consecutivo": val.Consecutivo,
                                    "registrationNumber": cab.registrationNumber,
                                    "Estado": (estado.label == undefined ? estado : estado.label),
                                    "agente": cab.agente,
                                    "Fecha": val.FechaCreacion,
                                    "Administrador": cab.administrador

                                };
                            })
                            DescargarExcel(data, Campos, `Reporte de requerimientos${(tabSel == 0 ? "" : (tabSel == 1 ? " asignados" : (tabSel == 2 ? " cerrados" : "")))}`)
                        }}>
                            <i className="bi-file-earmark-excel"></i></button>

                    </Box>
                )}

                initialState={{
                    columnVisibility: { Porc: false }, density: 'compact', sorting: [
                        {
                            id: 'Porc', //sort by age by default on page load
                            desc: true,
                        },
                        {
                            id: 'FechaCambio', //sort by age by default on page load
                            desc: true,
                        }
                    ]
                }}
            />)

    }
    //PREPARA LA INFORMACION PARA ABRIR EL MODAL DLP
    const AbrirModalCheckDLP = (row: any) => {
        setReqSeleccionado(row);
        setModalDLP(true);
    }


    return (
        <>
            <PageTitle>Interfaz de requerimientos</PageTitle>
            <BlockUi tag="div" keepInView blocking={loader ?? false}  >
                <div >
                    {/* Este espacio es la cabecera de la pagina */}
                    <div className="card">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="mx-auto">
                                <div className="ms-3 text-center">
                                    <h3 className="mb-0">Gestión Requerimientos </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row col-sm-12 col-md-12 col-xs-12 mx-auto">
                                {
                                    (ReqFiltrados.length > 0 && (perfil.isAdmin ? IndicadoresAdmin : IndicadoresGenerales).map((element: any) => {



                                        return (
                                            <div key={`indicadores_${element.Estado}`} className={`row card shadow-sm col-sm-2 col-md-2 col-xs-2 mx-auto `}
                                                title={element.Descripcion}
                                                style={{
                                                    backgroundColor: "#b6fffe "
                                                }}
                                            >
                                                <div className="m-3 text-center">
                                                    <h2 className={`mb-0`} style={{ fontSize: '28px' }}>
                                                        <span id={element.Estado}>{element.total(ReqFiltrados)}</span></h2>
                                                    <span className={`text-muted`}>{element.Estado}</span>
                                                </div>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-sm d-flex flex-row  justify-content-between">
                        {/* <div className="d-flex justify-content-start ">
                            <Button title="Filtros de Clientes, Agentes y Estados" className="mb-6 mx-2 mt-4 btn btn-sm btn-primary" onClick={() => { setShowFiltros(true) }}><i className="bi-filter"></i></Button>
                        </div> */}
                        <div className="d-flex justify-content-end mt-2 m-2">
                            <div style={{ float: 'right' }}>
                                {/* Espacio para un filtro */}
                            </div>
                        </div>
                    </div>
                    {/* Fin del encabezado */}
                    <div className="row w-100">
                        <ul className="nav nav-tabs nav-pills nav-pills-custom">
                            {listTabsRequerimientos.map((tab, idx) => {
                                return (<li className="nav-item mb-3" key={`tabenc_${idx}`}>
                                    <button
                                        onClick={() => {
                                            settabSel(idx)
                                            // setColumnFilters([])
                                            // setGlobalFilter('')
                                            // setPagination({
                                            //     pageIndex: 0,
                                            //     pageSize: 10,
                                            // })
                                            // setSorting([])
                                        }
                                        }
                                        className={`nav-link w-224px  h-70px ${tabSel === idx ? "active btn-active-light" : ""
                                            } fw-bolder me-2`}
                                        id={`tab${idx}`}
                                    >
                                        <div className="nav-icon me-3">
                                            {(tab.icon != "") && (<Equalizer color={tab?.color == 'danger' ? 'error' : tab?.color} />)}
                                        </div>
                                        <div className="ps-1">
                                            <span className={`nav-text text-${tab?.color ?? 'gray-600'} fw-bolder fs-6`} >
                                                {`${tab.titulo}${tab.total != undefined ? ` (${tab.total(ReqFiltrados)})` : ""}`}
                                            </span>
                                            <span className="text-muted fw-bold d-block pt-1">
                                                {tab.subtitulo}

                                            </span>
                                        </div>
                                    </button>
                                </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="tab-content">
                        {
                            ([0, 1, 2, 3, 4, 5, 6].includes(tabSel) &&
                                MontarTabla())}




                    </div>
                </div>

            </BlockUi>
            {/* Modal detalles de los requimientos */}
            {(show) && (<ModalDetalles
                show={show}
                setshow={setshow}
                ReqSeleccionado={ReqSeleccionado}
                Detalles={Detalles}
                GuardarObservaciones={GuardarObservaciones}
                UserActual={UserActual}
            />)}

            {(showedit) && (<ModalEditarReq
                showedit={showedit}
                setshowedit={setshowedit} />)}
            {/* ModalAsignacion */}
            {(ShowAsignacion) && (<ModalAsignacion
                ShowAsignacion={ShowAsignacion}
                setShowAsignacion={setShowAsignacion} />)}
            {/* Modal para diagnostico */}
            {(ShowModalDiag) && (<ModalDiagnostico
                ShowModalDiag={ShowModalDiag}
                setShowModalDiag={setShowModalDiag}
                ReqSeleccionado={ReqSeleccionado}
                InfDiag={InfDiag}
                InfObs={InfObs} />)}
            {/* Creacion Modal DLP */}
            {(ModalDLP) && (<ModalSoporteDLP
                ModalDLP={ModalDLP}
                setModalDLP={setModalDLP}
               setShowSt={setShowSt} />)}
            {/* Modal Filtro */}
            {/* {(ShowFiltros) && (<ModalFiltros
                ShowFiltros={ShowFiltros}
                setShowFiltros={setShowFiltros}
                Clientes={Clientes}
                ClienteSeleccionado={ClienteSeleccionado}
                setClienteSeleccionado={setClienteSeleccionado}
                Agentes={Agentes}
                setAgentesSeleccionado={setAgentesSeleccionado}
                AgentesSeleccionado={AgentesSeleccionado}
                Estados={Estados}
                setEstadoSeleccionado={setEstadoSeleccionado}
                EstadoSeleccionado={EstadoSeleccionado}
            />)} */}
            {/* Creacion ST */}
            {(ShowSt) && (<CreacionSt
                show={ShowSt}
                handleClose={setShowSt} />)}

            {/* Ventana de la orde de ejecucion */}
            {(ShowModalOrden) && (
                <ModalEjecucionOrden
                    Show={ShowModalOrden}
                    setShowModalOrden={setShowModalOrden} />)}
            {(showModalProg) && (
                <ModalEditarProgramacion showedit={showModalProg} setshowedit={setShowModalProg} />)}

              {(showAdjuntoModal) && (<Adjunto showedit={showAdjuntoModal} setshowedit={setshowAdjuntoModal}/>)}  
        </>
    )
}