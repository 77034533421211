import moment from "moment";
import "moment/locale/es"
import "moment-timezone"
export const formatSimple = "YYYY/MM/DD";
export const  formatable = "YYYY/MM/DD HH:mm";
export const  formatableJson_HHMMss = "YYYY-MM-DDTHH:mm:ss";
export const  formatSimpleJson = "YYYY-MM-DD";
export const  formatSimpleJsonColombia = "DD-MM-YYYY";
export const  formatFechasView = "DD/MM/YYYY";
export const  formatViewHoraMinuto = "DD/MM/YYYY HH:mm";
export const  format_YYYYMMDDHHmm = "YYYY-MM-DD HH:mm";

var utcMoment = () => {return moment().tz('America/Bogota').locale('es')};
export const FechaServidor = () => {return new Date( utcMoment().format() ) };
export const GetFechaServidor = () => {return moment(new Date( utcMoment().format() )) };
export const FechaMomentUtc = utcMoment;
export let FechaServidorPeriodo =utcMoment().format("MYYYY");
export let FechaServidorString=GetFechaServidor().format(formatableJson_HHMMss);

export function GetPeriodoByFecha(Fecha: any) {

  return moment(Fecha).format("MYYYY");
}
// milisegundos a time
export  function msToTime(s : number) {

    // Pad to 2 or 3 digits, default is 2
    function pad(n : number, z : number = 2) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }

  export  function msToTimeSeconds(s : number) {

    // Pad to 2 or 3 digits, default is 2
    function pad(n : number, z : number = 2) {
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
   
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) ;
  }

  export function locateFormatNumberNDijitos(number: number, digits: number)
  {
    return number.toLocaleString?.('es-En', {
      minimumFractionDigits:digits,
      maximumFractionDigits: digits
    } )
  } 
  export function locateFormatPercentNDijitos(number: number, digits: number)
  {
    return number.toLocaleString?.('es-En', {
      style: "percent",
      minimumFractionDigits:digits,
      maximumFractionDigits: digits
    } )
  } 

  export function formatNumberChart(number :number)
  {
      if (!isNaN(number))
          return (number >= 1000000) ? `${(number / 1000000).toFixed(2)}M` : `${(number / 1000).toFixed(2)}K`
  
      return `${number.toFixed(2)}`
  }




export function gethoras(segundos: number) {
  let hour = Math.floor(segundos / 3600);
  hour = (hour < 10) ? 0 + hour : hour;
  let minute = Math.floor((segundos / 60) % 60);
  minute = (minute < 10) ? 0 + minute : minute;
  let second = segundos % 60;
  second = (second < 10) ? 0 + second : second;
  return hour + ':' + minute + ':' + Math.round(second);
};

export const DeserializarJSon = (data:any) =>{
  return EsJson(data) ? JSON.parse(data) : data;
};
export function EsJson(dato:any){
  try {
      JSON.parse(dato);
  } catch (e) {
      return false;
  }
  return true;
};

export const ValidaCorreo = (email: string) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return regex.test(email)
};

export function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
     var ascii = binaryString.charCodeAt(i);
     bytes[i] = ascii;
  }
  return bytes;
}

export const getMensajeErrorAxios = (error: any) => {
 let errorMensaje : string = (  error.response?.data != undefined ?
  error.response.data: error.message) ?? 'Error';  
   const isSplit = errorMensaje.split(' at ');

  return isSplit.length > 1 ? isSplit[0]?.split(':')[1] : errorMensaje
};
